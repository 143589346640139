

import {
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonMenuButton,
  IonModal,
  IonItem,
  IonButton,
  IonIcon,
  IonInput,
  IonLabel,
  IonContent,
  IonBackButton,
  IonSelect,
  IonSelectOption,
  toastController,
} from "@ionic/vue";

import { chevronDownOutline, linkOutline, peopleOutline, } from "ionicons/icons";
import { computed, defineComponent, ref } from 'vue';
import { useAuthStore } from "@/store/authStore";
import { storeToRefs } from "pinia";
import { useMerchantStore } from "@/store/merchantStore";
import { getToLink } from "@/utils/urlHelper";
import { ItemAction } from "@/models/orderService/ReturnOrder";

  export default defineComponent({
    props: {
      pageName: String,
      pageDescription: String,
      hilight: {default: false, type: Boolean},
      backButton: {default: false, type: Boolean},
      actions: {default: undefined, type: Array<ItemAction>},
    },
    emits: {
      clickAction: (action: ItemAction) => true,
    },
    components: {
      IonHeader,
      IonToolbar,
      IonTitle,
      IonButtons,
      IonMenuButton,
      IonModal,
      IonItem,
      IonButton,
      IonIcon,
      IonInput,
      IonLabel,
      IonContent,
      IonBackButton,
      IonSelect,
      IonSelectOption,
    },
    name: "PageHeader",
    setup (_props, {emit, slots}){
      const auth = useAuthStore();
      const merchantStore = useMerchantStore();

      const {username} = storeToRefs(auth);
      const isMerchantSelectOpen = ref(false);
      const isUserModalOpen = ref(false);

      const currentChannel = computed(()=>merchantStore.currentChannel);

      const setMerchantSelectOpen = (open: boolean) => (isMerchantSelectOpen.value = open);
      const setUserModalOpen = (open: boolean) => (isUserModalOpen.value = open);

      const selectChannel = async (id: number) => {
        if(!auth.merchantId)
          return;

        await auth.loginToChannel(auth.merchantId, id);
      }
      
      const clickAction = (action: ItemAction) => {
        emit('clickAction', action);
      }

      const defaultSlotUsed = () => {
        const defaultSlot = slots?.default;
        return !!defaultSlot;
      }

      const copyLink = async () => {
        const link = getToLink();        
        navigator.clipboard.writeText(link);
        const toast = await toastController.create({
          message: "Copied link to clipboard",
          duration: 1500,
          position: "top",
          color: "light",
        });
        await toast.present();
      }

      return {
        peopleOutline, chevronDownOutline,
        username,
        channels: merchantStore.currentMerchant?.channels,
        currentChannel,
        selectChannel,
        isMerchantSelectOpen,setMerchantSelectOpen,
        isUserModalOpen,setUserModalOpen,
        defaultSlotUsed,
        copyLink, linkOutline, clickAction,
      }
    }
  });
