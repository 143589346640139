
import {  IonPage, IonContent, IonGrid,IonRow,IonCol,IonButton, IonChip, InfiniteScrollCustomEvent,  
  IonInfiniteScroll, IonInfiniteScrollContent,IonIcon,
  IonList, IonItem, IonLabel, IonSpinner,
} from '@ionic/vue';
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import ReturnMenu from "@/views/Return/ReturnMenu.vue";
import TableHead from "@/views/Table/TableHead.vue";

import { computed, defineComponent, onMounted, ref, Ref, watch } from 'vue';
import { getCancelToken, isCancelError, useStore } from '@/store';
import { useRouter } from 'vue-router';
import PageDescription from '@/components/PageDescription.vue';
import { ColumnDefinition, ListViewState, ListDefinitions, SearchDefinition } from '@/models/orderService/ReturnsListFilter';

import * as moment from 'moment-timezone';
import { filterOutline, closeOutline, eyeOutline, checkmarkDoneOutline, checkboxOutline } from "ionicons/icons";
import { useAuthStore } from '@/store/authStore';
import { useErrorBox } from '@/components/errorBox';
import { useOrderStore } from '@/store/orderStore';
import { ItemAction, ReturnOrderDto } from '@/models/orderService/ReturnOrder';
import { storeToRefs } from 'pinia';
import { useOgoEvents } from '@/composables/useOgoEvents';
import { CancelTokenSource } from 'axios';

export class ActiveFilter {
  filterId: string;
  filterTitle: string;
  selectObject: any;
  selectTitle: string;

  selectText(){
    return this.selectTitle;
  }

  constructor(filterId: string, filterTitle: string, selectObject: any, selectTitle: string) {
  this.filterId = filterId;
  this.filterTitle = filterTitle;
  this.selectObject = selectObject;
  this.selectTitle = selectTitle;
  }
}


export default defineComponent({
  name: 'ReturnsList',
  components: { 
    // IonSplitPane,
    PageHeader,
    PageFooter,
    IonGrid,
    IonRow,
    IonCol, 
    IonPage, 
    IonContent,
    IonButton,
    IonChip,    
    IonList,
    IonItem,
    IonIcon,
    IonLabel,
    IonSpinner,

    // MenuComponent,
    ReturnMenu,
    PageDescription,
    TableHead,
    IonInfiniteScroll, IonInfiniteScrollContent,
  },
  data() {
    return {
      chartOptions: {
        responsive: true
      }
    }
  },
  setup (){
    const store = useStore();
    const router = useRouter();
    const auth = useAuthStore();
    const orderStore = useOrderStore();

    const { showError } = useErrorBox();
    
    const momentjs: any = moment;
    moment.locale(store.state.locale ?? "en");

    const { merchantId, channelId } = storeToRefs(auth);

    const returnListDefinitions: Ref<ListDefinitions|undefined> = ref(undefined);

    const nextLine:  Ref<number> = ref(0);
    const listResult: Ref<Array<ReturnOrderDto>> = ref([]);      
    const loadMoreVisible: Ref<boolean> = ref(false);
    const processing: Ref<boolean> = ref(false);
    let cancelToken: CancelTokenSource|undefined = getCancelToken();
    
    const initialize = async () => {
        listResult.value = [];
        if(!merchantId.value || !channelId.value)
            return;
        
        processing.value = true;
        try {
            // Load definitions
            returnListDefinitions.value = await orderStore.getReturnOrderDefinitions();
            loadListViewState();
        } 
        catch(error) {
            showError(error, "Error");
        }
        finally {
            processing.value = false;
        }
        
    };
    onMounted( initialize );
    useOgoEvents({ returnChanged: initialize });
    watch(channelId, initialize);
    
    const activeFilters = computed(() => {
      if(!returnListDefinitions.value)
        return [];

      return Object.entries(returnListDefinitions.value as object)
        .filter(i=>(i[1]).inlineSearchSelectObject)
        .map (i => {
          return new ActiveFilter(i[0], i[0], i[1].inlineSearchSelectObject, i[1].inlineSearchSelectTitle);
        });
    });

    const filterChanged = (filterKey: string, _filter:ColumnDefinition, filterValue: any, filterTitle: string) => {
      if(returnListDefinitions.value) {
        if(filterValue === undefined || filterValue === null || filterValue === "" || filterTitle === undefined || filterTitle === "") {
          returnListDefinitions.value[filterKey].inlineSearchSelectObject = undefined;
          returnListDefinitions.value[filterKey].inlineSearchSelectTitle = undefined;
        } else {
        returnListDefinitions.value[filterKey].inlineSearchSelectObject = filterValue;
        returnListDefinitions.value[filterKey].inlineSearchSelectTitle = filterTitle;
        }
      }  
    }

    const sortChanged = (filterKey: string, _filter:ColumnDefinition, sort: boolean, sortDesc: boolean) => {
      // Reset old sort order
      Object.entries(returnListDefinitions.value as object).forEach(e => {
        if(e[0] !== filterKey) {
          e[1].sortOrder = undefined;
        }
      });
      
      // Update new sort order
      if(returnListDefinitions.value) {
        returnListDefinitions.value[filterKey].sortOrder = sort ? 1 : undefined;
        returnListDefinitions.value[filterKey].sortDesc = sortDesc;
      }
    }

    // Remove filter
    const removeFilter = (_event: CustomEvent, filter: ActiveFilter) => {
      if(returnListDefinitions.value) {
        returnListDefinitions.value[filter.filterId].inlineSearchSelectObject = undefined;
        returnListDefinitions.value[filter.filterId].inlineSearchSelectTitle = undefined;
      }
    }

    const saveListViewState = () => {
      if(returnListDefinitions.value) {
        const savedState = new ListViewState();
        savedState.stateFrom(returnListDefinitions.value);
        orderStore.returnOrderListPage = savedState;
        // console.log("saveListViewState",orderStore.returnOrderListPage);
        // console.log("saveListViewState",JSON.stringify(orderStore.returnOrderListPage));
      }
    }

    const loadListViewState = () => {
      if(returnListDefinitions.value) {
        // console.log(orderStore.returnOrderListPage);
        ListViewState.stateTo(orderStore.returnOrderListPage, returnListDefinitions.value);
      }
      // console.log("loadListViewState",orderStore.returnOrderListPage);
    }

    const actions = computed(() :Array<ItemAction> => {
      let actionList: Array<ItemAction>|undefined = undefined;
      listResult.value
        .filter(i=>i.selected)
        .forEach(i => {
          if(actionList == undefined)
            actionList = [...i.actions];
          else
            actionList = actionList.filter(j => i.actions.findIndex(k=>k.code == j.code) !== -1);
        });

      return actionList ?? [];
    });

    watch(returnListDefinitions, async (_newValue, _oldValue) => {
      // reset and reload
      listResult.value = [];
      nextLine.value = 0;
      await loadData(50);

      saveListViewState();
    },{deep: true});

    // watch for and channelId changes
    watch(channelId, async (_newValue, _oldValue) => {
      // reset and reload
      listResult.value = [];
      nextLine.value = 0;
      await loadData(50);
    });

    /// Load data to list
    const loadData = async (take: number) => {
      if(!channelId.value || !returnListDefinitions.value)
        return;

      if(cancelToken) cancelToken.cancel("Cancel old request");
        cancelToken = getCancelToken();

      const search = new SearchDefinition();
      search.limit.skip = nextLine.value;
      search.limit.take = take;

      Object.entries(returnListDefinitions.value as object).forEach(e => {
        const key = e[0] as string;
        const f = e[1] as ColumnDefinition;

        // Update new sort order
        if(f.sortOrder)
          search.sort[key] = !!f.sortDesc;

        // Update new filter
        if(f.inlineSearchSelectObject) {
          search.inlineSearch[key] = f.inlineSearchSelectObject;
        }
      });

      // sleep for 100ms if processing previous request
      if(processing.value)
        await new Promise(r => setTimeout(r, 100));

      processing.value = true;
      try {
        loadMoreVisible.value = false;
        const r = await orderStore.getReturnOrders(channelId.value, search, cancelToken);
        r.forEach(row => { listResult.value.push(row); });
        nextLine.value += r.length;

        loadMoreVisible.value = r.length >= take;
      } catch(error) {
        // No errorbox if cancel
        if(isCancelError(error)){
          return;
        }
        showError(error, "Error");
        loadMoreVisible.value = false;
      } finally {
        processing.value = false;
      }
    }

    const ionInfinite = async (ev: InfiniteScrollCustomEvent) => {
      await loadData(50);
      ev.target.complete();
    };

    const runAction = async (action: ItemAction) => {
      if(!channelId.value)
        return;

      processing.value = true;
      try {
        const itemList = listResult.value.filter(i=>i.selected);
        const updatedList = await orderStore.runReturnAction(channelId.value, itemList, action.code);

        listResult.value = listResult.value.map(i => {
          const updated = updatedList.find(j=>j.returnId == i.returnId);
          return updated ?? i;
        });
      } catch(error) {
        showError(error, "Error");
      } finally {
        processing.value = false;
      }
    }

    const mergeReturns = async () => {
      const selected = listResult.value.filter(i=>i.selected);
      if(selected.length < 2)
        return;

        if (!channelId.value)
          return;

      processing.value = true;
      try {
        const ids = selected.map(i=>i.returnId);
        await orderStore.mergeReturnOrders(channelId.value, ids);
      }
      catch(error) {
        showError(error, "Error");
      }
      finally {
        processing.value = false;
      }

    }
      

  return {
    router,returnListDefinitions,filterChanged,sortChanged,activeFilters,removeFilter,
    filterOutline,closeOutline,eyeOutline,checkmarkDoneOutline, checkboxOutline,
    listResult,loadData,ionInfinite,
    momentjs,loadMoreVisible,
    processing, actions, runAction, mergeReturns
  }
}
});

