

import { useConfigStore } from "@/store/configStore";
import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar } from "@ionic/vue";
import { informationCircleOutline, peopleOutline, helpOutline, } from "ionicons/icons";
import { computed, defineComponent, onMounted, PropType, ref } from "vue";

type PredefinedColors =
  | 'primary'
  | 'secondary'
  | 'tertiary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'light'
  | 'medium'
  | 'dark';

type ButtonFill = 'clear' | 'outline' | 'solid' | 'default';
type ButtonSize = 'small' | 'default' | 'large';
type ButtonIcon = 'peopleOutline' | 'helpOutline' | 'informationCircleOutline';

  export default defineComponent( {
    props: {
      text: { type: String, required: true },
      title: { type: String, required: true },
      code: { type: String, required: true },
      style: { type: Object, default() { return {}}},
      size: { type: String as PropType<ButtonSize>, default: "default" },
      icon: { type: String as PropType<ButtonIcon>, default: "helpOutline" },      
      color: { type: String as PropType<PredefinedColors>, default: "primary" },
      fill: { type: String as PropType<ButtonFill>, default: "solid"  },
    },
    components: {
      IonButton,
      IonIcon,
      IonModal,
      IonHeader,
      IonToolbar,
      IonButtons,
      IonTitle,
      IonContent,
    },
    name: "ModalHelpButton",
    setup (props) {  
      const configStore = useConfigStore();
      const isModalOpen1 = ref(false);
      const contentHtml = ref("");

      onMounted(async () => {
        contentHtml.value = await configStore.getSnippet(props.code);
      });
      
      const selectedIcon = computed(() => {
        if(props.icon === "peopleOutline")
          return peopleOutline;

        if(props.icon === "helpOutline")
          return helpOutline;

        if(props.icon === "informationCircleOutline")
          return informationCircleOutline;

        return undefined;
      });

      return {
        isModalOpen1,
        selectedIcon,
        contentHtml,
      }
    }
  });
