
import { IonGrid,IonRow,IonCol,IonDatetimeButton, IonModal,IonDatetime, IonToggle, IonButtons, IonTitle, IonToolbar, IonHeader, IonItem, IonLabel, IonContent, IonButton, IonIcon, IonText, IonProgressBar, IonSelect, IonSelectOption } from '@ionic/vue';

import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement } from 'chart.js'
import { computed, defineComponent, onMounted, ref, Ref, watch } from 'vue';

import { useErrorBox } from '@/components/errorBox';
import { useTrackingStore } from '@/store/trackingStore';
import { ShipmentMetrics, ShipmentTrackingStatus } from '@/models/trackingService/ShipmentMetrics';
import * as moment from 'moment-timezone';
import { useAuthStore } from '@/store/authStore';
import { storeToRefs } from 'pinia';
import { OrderTypeEnum, ShipmentDto } from '@/models/trackingService/Shipment';
import { useRouter } from 'vue-router';
import { lockClosedOutline } from 'ionicons/icons';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement);

export class ShipmentMetricsDataViewModel
{
    key: string;
    status: ShipmentTrackingStatus;
    deliveryType: string;
    values: Array<{ date: Date, quantity: number, stopped: number, ogoIssues: number, merchantIssues: number, isLate: number }>;
    info: string;

    addQuantity(day: Date, quantity: number, stopped: number, merchantOpenIssues: number, ogoOpenIssues: number, isLate: number){
      const str = day.toDateString();
      let val = this.values.find(v => v.date.toDateString() === str);
      if(!val){
        val = { date: new Date(day), quantity: 0, stopped: 0, ogoIssues: 0, merchantIssues: 0, isLate: 0};
        this.values.push(val);
      }

      val.quantity += quantity;
      val.stopped += stopped;
      val.isLate += isLate;
      val.merchantIssues += merchantOpenIssues;
      val.ogoIssues += ogoOpenIssues;
    }

    getSortedQuantity(){
      return this.values.sort((a,b) => a.date.getTime() - b.date.getTime());
    }

    constructor(dto: ShipmentMetrics, key: string){
            this.key = key;
            this.status = dto.status;
            this.deliveryType = dto.deliveryType;
            this.values = [];
            this.info = dto.info;
    }
}


export default defineComponent({
  name: 'TrackingDashboardComponent',
  components: { 
    IonGrid,
    IonRow,
    IonCol, 
    IonDatetimeButton,IonModal,IonDatetime,
    IonSelect, IonSelectOption,
    IonButtons,IonTitle,IonToolbar,IonHeader,
    IonItem, IonLabel, IonContent,
    IonButton,
    IonToggle,
    IonIcon,
    IonText,
    IonProgressBar,
  },
  data() {
    return {
      chartOptions: {
        responsive: true
      }
    }
  },
  setup (){    
    const trackingStore = useTrackingStore();
    const auth = useAuthStore();
    const router = useRouter();

    const { showError } = useErrorBox();

    const progress = ref(0);
    const filtersVisible = ref(false);

    const momentjs: any = moment;
    moment.locale(auth.locale);

    const { dashboardState: savedState } = storeToRefs(trackingStore);

    const metricsData: Ref<Record<string,ShipmentMetricsDataViewModel>> = ref({});
    const dateList: Ref<Array<Date>> = ref([]);

    const isShipmentListOpen = ref(false);
    const shipmentDetailsList: Ref<Array<ShipmentDto>> = ref([]);

    const processing = ref(false);
    
    const removeDays = (date: Date, days: number) => {
      const result = new Date(date)
      result.setDate(result.getDate() - days)
      return result
    }

    const orderTypeOptions = [
            { id: OrderTypeEnum.SalesOrder, title: "Orders" },
            { id: OrderTypeEnum.Return, title: "Returns" },
        ];

    const endDateStr: Ref<string> = ref(new Date().toISOString());
    const startDateStr: Ref<string> = ref(removeDays(new Date(), 30).toISOString());

    const adminsRole = computed(()=>auth.filteredRoles.includes("Admins"));

    const mapRowKey = (item: ShipmentMetrics) :string => {
      if(savedState.value.showDeliveryType && savedState.value.showDeliveryState){
        return item.status + " - " + item.deliveryType;
      } else if(savedState.value.showDeliveryState){
        return item.status.toString() ?? "";
      } else if(savedState.value.showDeliveryType){
        return item.deliveryType;
      } else {
        return "shipments";
      }
    }

    const sortCalculate = (a: ShipmentMetricsDataViewModel, b: ShipmentMetricsDataViewModel) => {
      let r = 0;
      if(savedState.value.sortBy == "State"){
        r = (a.status.toString()).localeCompare(b.status.toString()) || a.deliveryType.localeCompare(b.deliveryType);        
      } else if(savedState.value.sortBy == "Type"){
        r = a.deliveryType.localeCompare(b.deliveryType) || (a.status.toString()).localeCompare(b.status.toString());
      } else {
        r = 0;
      }

      return savedState.value.sortDesc ? -r : r;
      

      // if(savedState.value.showDeliveryType && savedState.value.showDeliveryState){
      //   return (a.status.toString()).localeCompare(b.status.toString()) || a.deliveryType.localeCompare(b.deliveryType);
      // } else if(savedState.value.showDeliveryState){
      //   return (a.status.toString()).localeCompare(b.status.toString());
      // } else if(savedState.value.showDeliveryType){
      //   return a.deliveryType.localeCompare(b.deliveryType);
      // } else {
      //   return 0;
      // }
    }

    const sortedData = computed(() => {
      return Object.values(metricsData.value).sort((a,b) => sortCalculate(a,b));
    });

    const refreshCharts = async () => {
      if(processing.value) return;

      const channelId = (savedState.value.showAllChannels && adminsRole.value) ? 0 : auth.channelId ?? -1;
      processing.value = true;
      try {
        dateList.value = [];
        metricsData.value = {};

        const currentDate = new Date(startDateStr.value);
        const endDate = new Date(endDateStr.value);
        while(currentDate <= endDate){
          const dayData = await trackingStore.metricsOfDay(currentDate,channelId, savedState.value.orderType);
          dateList.value.push(new Date(currentDate));

          // Add empty data for all keys
          Object.entries(metricsData.value).forEach(entry => {
            entry[1].addQuantity(currentDate, 0, 0, 0, 0, 0);
          });

          // Add real data
          dayData.forEach(entry => {
            const key = mapRowKey(entry);

            // Add and init data if not exists
            if(!metricsData.value[key]){
              metricsData.value[key] = new ShipmentMetricsDataViewModel(entry, key);
              dateList.value.forEach(date => {
                metricsData.value[key].addQuantity(date, 0, 0, 0, 0, 0);
              });
            }

            metricsData.value[key].addQuantity(currentDate, entry.quantity, entry.stopped, entry.merchantOpenIssues, entry.ogoOpenIssues, entry.isLate);
          });

          currentDate.setDate(currentDate.getDate() + 1);
        }
      } catch (err) {
        await showError(err, "Error")
      } finally {
        processing.value = false;
      }

    }

    onMounted(async () => {
      await refreshCharts();
    });


    // Watch for changes in saved state. Deep watch is needed for nested objects.
    watch(savedState.value, async (_newVal, _oldVal) => {
      // Refresh charts only if showDeliveryType is changed
      if(dateList.value.length > 0){
        await refreshCharts();
      }
    }, { deep: true });

    
    watch(startDateStr, async (_newVal, _oldVal) => {
      if(dateList.value.length > 0){
        await refreshCharts();
      }
    });

    watch(endDateStr, async (_newVal, _oldVal) => {
      if(dateList.value.length > 0){
        await refreshCharts();
      }
    });

    const showShipments = async (cell: ShipmentMetricsDataViewModel, date: Date) => {
      const channelId = (savedState.value.showAllChannels && adminsRole.value) ? 0 : auth.channelId ?? -1;

      progress.value = 0;
      shipmentDetailsList.value = [];
      
      const shipments = await trackingStore.searchShipments(
        channelId,
        savedState.value.showDeliveryState ? cell.status : undefined,
        savedState.value.showDeliveryType ? cell.deliveryType : undefined,
        savedState.value.orderType ?? undefined,
        date
      );

      // console.log({channel: channelId,
      //   status: savedState.value.showDeliveryState ? cell.status : undefined,
      //   type: savedState.value.showDeliveryType ? cell.deliveryType : undefined,
      //   date: date})

      if(shipments.length === 0) 
        return;

      shipmentDetailsList.value = shipments;
      isShipmentListOpen.value = true;
    }

    const openTrackingDetails = (id: string, channel: number) => {
      if (savedState.value.orderType === OrderTypeEnum.Return)
        router.push({name: 'ReturnRequestDetails', params: {id: id}});
      else
        router.push({name: 'TrackingDetails', params: {id: id, channel: channel}});
    }

    const refreshAllFromDay = async () => {     
      processing.value = true;
      let index = 0;
      shipmentDetailsList.value.forEach(async shipment => {
        await trackingStore.getTracking(shipment.uid, shipment.channelId);
        progress.value = index++ / shipmentDetailsList.value.length;
      });

      progress.value = 0;
      processing.value = false;
      await refreshCharts(); 
      isShipmentListOpen.value = false;
    };

  return {
    progress,
    refreshAllFromDay,
    adminsRole,
    router,
    showShipments,
    startDateStr,endDateStr,
    metricsData,dateList,
    momentjs,
    savedState,
    sortedData,
    isShipmentListOpen,shipmentDetailsList,
    processing,
    lockClosedOutline,
    filtersVisible,
    orderTypeOptions,
    openTrackingDetails
  }
}
});

