
import {
  IonTitle, IonPage, IonList, IonItem, IonGrid,
  IonRow, IonCol, IonButton, IonLabel, IonContent, loadingController, IonSelect, IonSelectOption, IonInput, IonImg, IonModal, IonTextarea,
  IonIcon, IonButtons, IonToolbar, IonHeader,
IonAlert,
IonicSafeString
} from '@ionic/vue';
import { Ref, computed, defineComponent, onMounted, ref } from 'vue';
import { getTrackingDomain, useStore } from '@/store';
import { useRouter } from 'vue-router';

import { RefundStatusEnum, RefundTypeEnum } from '@/models/orderService/Return';
import { EcomReturnStatusEnum, PhotoLink, ReturnOrderDto, ReturnOrderStatusEnum, ReturnOrderUpdateDto, ReturnRow } from '@/models/orderService/ReturnOrder';
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import PageDescription from '@/components/PageDescription.vue';

import SingleOrder from '@/views/Return/SingleOrder.vue';
import PhotoGallery from '@/components/PhotoGallery.vue';

import * as moment from 'moment-timezone';
import { useErrorBox } from '@/components/errorBox';
import { useAuthStore } from '@/store/authStore';
import { useOrderStore } from '@/store/orderStore';
import { stringToHash } from '@/utils/changeTracking';
import { useNotificationStore } from '@/store/notificationStore';
import { MessageDto } from '@/models/notificationService/Messaging';
import { cloudDoneOutline, sendOutline, mailUnreadOutline, mailOpenOutline, sadOutline, } from 'ionicons/icons';
import { useConfigStore } from '@/store/configStore';
import { CampaignWithFeedbackView } from '@/models/feedbackService/campaign';
import { useFeedbackStore } from '@/store/feedbackStore';
import TrackingEvents from '@/components/TrackingEvents.vue';
import { eventBus } from '@/composables/useOgoEvents';

export default defineComponent({
  name: 'ReturnDetails',
  components: {
    // IonSplitPane, 
    IonPage,
    IonTitle,
    IonList, IonItem, IonGrid, IonRow, IonCol, IonContent, IonInput,
    IonTextarea,
    IonButton,
    IonLabel,
    IonSelect, IonSelectOption,
    IonImg,
    IonModal,
    // MenuComponent,
    PageHeader,
    PageFooter,
    PageDescription,
    SingleOrder,
    PhotoGallery,
    IonIcon,
    IonButtons,
    IonToolbar,
    IonHeader,
    IonAlert,
    TrackingEvents
  },
  setup() {

    const store = useStore();
    const auth = useAuthStore();
    const router = useRouter();
    const orderStore = useOrderStore();
    const notificationStore = useNotificationStore();
    const { showError } = useErrorBox();
    const configStore = useConfigStore();
    const feedbackStore = useFeedbackStore();

    const momentjs: any = moment;
    moment.locale(store.state.locale ?? "en");

    const returnId: Ref<string | undefined> = ref(undefined);
    const returnDetails: Ref<ReturnOrderDto | undefined> = ref(undefined);

    const isPhotoGalleryOpen: Ref<boolean> = ref(false);

    const emailPreviewOpen = ref<boolean>(false);
    const previewMessage = ref<MessageDto|undefined>(undefined);
    const messages = ref<Array<MessageDto>>([]);
    const feedback = ref<CampaignWithFeedbackView|undefined>(undefined);
    const fromEcom = ref<boolean>(false);
    const showCreateShipmentButton = ref<boolean>(false);

    const isAdmin = computed(() => { return auth.filteredRoles.includes("Admins"); });

    const refundTypeOptions = ref<Array<{ id: RefundTypeEnum, title: string }>>([
      { id: RefundTypeEnum.NotSelected, title: "Not selected" }, 
      { id: RefundTypeEnum.Refund, title: "Refund money" }, 
      { id: RefundTypeEnum.StoreCredit, title: "Store credit" }
    ]);
          
    const refundOptions = [
      { id: RefundStatusEnum.NotAvailable, title: "Not Available" },
      { id: RefundStatusEnum.Validating, title: "Validating" },
      { id: RefundStatusEnum.Waiting, title: "Waiting for products" },
      { id: RefundStatusEnum.Refunded, title: "Refunded" },
      { id: RefundStatusEnum.Rejected, title: "Rejected" },
    ];


    const loading = async () => {
      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: "Processing...",
        duration: undefined,
      });

      await loading.present();
      return loading;
    };

    onMounted(async () => {
      const id = router.currentRoute.value.params["id"].toString();
      if (id) {
        returnId.value = id;

        if (!auth.channelId)
          return;

        const l = await loading();
        try {
          returnDetails.value = await orderStore.getReturnOrder(auth.channelId, returnId.value);
          savedHash.value = hashComponentState.value;

          if(returnDetails.value?.channelId && returnDetails.value?.orderInfo?.orderId){
            const orderMessages = await notificationStore.getMessages(returnDetails.value.channelId,returnDetails.value.orderInfo?.orderId);
            const returnMessages = await notificationStore.getMessages(returnDetails.value.channelId,returnDetails.value.returnId);
            messages.value = orderMessages
              .concat(returnMessages)
              .sort((a,b) => momentjs(b.createTime).diff(momentjs(a.createTime)));          
              
            feedback.value = await feedbackStore.getCustomerCampaign(returnDetails.value.channelId, returnDetails.value.orderInfo?.orderId, "order.return", "en", returnDetails.value.returnId);  

            if(returnDetails.value.orderInfo.integrationId != undefined &&
              returnDetails.value.orderInfo.integrationId !== "00000000-0000-0000-0000-000000000001" && // Warehouse
              returnDetails.value.orderInfo.integrationId !== "00000000-0000-0000-0000-000000000002" && // Api
              returnDetails.value.orderInfo.integrationId !== "00000000-0000-0000-0000-000000000003" && // Manual
              returnDetails.value.orderInfo.integrationId !== "00000000-0000-0000-0000-000000000004") // Old Api
                fromEcom.value = true;

            // temp fix for refund type
            fixRefundType();
          }

        } catch (error) {
          showError(error as string, "Error");
          router.back();
        } finally {
          await l.dismiss();
        }

      } else {
        router.back();
      }
    });

    const getRefundQuantity = (row: ReturnRow) => {
      if (!row) return 0;
      if (!row.replacementProducts || row.replacementProducts.length == 0) return row.returnedQuantity ?? 0;

      return row.replacementProducts.filter(i => i.action === "refund" ).length;
    }

    const totalReturnedProductCost = computed(() => {
      if (!returnDetails.value) return 0;

      // console.log(returnDetails.value.returnRows);
      const cost = returnDetails.value.returnRows
        .filter(i => i.returnedQuantity && i.refundUnitCost && getRefundQuantity(i)>0)
        .reduce((acc, i) => acc + (i.refundUnitCost || 0) * getRefundQuantity(i), 0);

      return cost - returnDetails.value.cost;
    });

    const fixRefundType = () => {
      if(!returnDetails.value)
        return;

      if(returnDetails.value.refundType !== RefundTypeEnum.NotSelected)
        return;

      returnDetails.value?.returnRows.forEach(i => {
        i.replacementProducts?.forEach(j => {
          if (j.action === "storeCredit"){
            returnDetails.value!.refundType = RefundTypeEnum.StoreCredit;
            return;
          }
          else if (j.action === "refund"){
            returnDetails.value!.refundType = RefundTypeEnum.Refund;
            return;
          }
        });
      });
      // debugger;
      // returnDetails.value!.refundType = RefundTypeEnum.Refund;
    }

    const openOrder = () => {
      if (returnDetails.value?.orderInfo?.orderReference) {
        window.location.assign(auth.myOGO1Domain + `/order/edit/${returnDetails.value?.orderInfo?.orderId}`);
      }
    }

    const openExchangeOrder = () => {
      if (returnDetails.value?.orderInfo?.exchangeOrderId) {
        window.location.assign(auth.myOGO1Domain + `/order/edit/${returnDetails.value?.orderInfo?.exchangeOrderId}`);
      }
      else if (returnDetails.value?.ecomInfo?.exchangeOrderReference) {
        window.location.assign(auth.myOGO1Domain + `/integration/order?shopId=${returnDetails.value?.orderInfo?.integrationId}&ids[]=${returnDetails.value?.ecomInfo?.exchangeOrderReference}`);
      }
    }

    const openMergedReturn = (id: string) => {
        router.push(`/return/request/${id}`);
    }

    const approveReturn = async () => {
      if (!returnDetails.value) return;

      const l = await loading();
      try {
        returnDetails.value = await orderStore.approveReturnOrder(returnDetails.value.channelId, returnDetails.value.returnId);
        if (returnDetails.value.trackingCodes.length > 0) {
          eventBus.emit('shipmentChanged', undefined);
        }
        savedHash.value = hashComponentState.value;
      } catch (error) {
        showError(error as string, "Error");
      } finally {
        await l.dismiss();
      }
    }

    const cancelReturn = async () => {
      if (!returnDetails.value) return;

      const l = await loading();
      try {
        returnDetails.value = await orderStore.cancelReturnOrder(returnDetails.value.channelId, returnDetails.value.returnId);
        savedHash.value = hashComponentState.value;
      } catch (error) {
        showError(error as string, "Error");
      } finally {
        await l.dismiss();
      }
    }

    const closeReturn = async () => {
      if (!returnDetails.value) return;

      const l = await loading();
      try {
        returnDetails.value = await orderStore.closeReturnOrder(returnDetails.value.channelId, returnDetails.value.returnId);
        savedHash.value = hashComponentState.value;
      } catch (error) {
        showError(error as string, "Error");
      } finally {
        await l.dismiss();
      }
    }

    const alertResult = async (ev: CustomEvent) => {
      // console.log(`Dismissed with role: ${ev.detail.role}`);

      if(ev.detail.role === 'approve-return-now')
        await approveReturn();

      if(ev.detail.role === 'create-now')
        await createReturnToEcom();

      if(ev.detail.role === 'refund-now')
        await completeReturnToEcom();

      if(ev.detail.role === 'cancel-return-now')
        await cancelReturn();
    }

    const createReturnToEcom = async () => {
      if (!returnDetails.value) return;

      const l = await loading();
      try {
        returnDetails.value = await orderStore.createReturnToEcom(returnDetails.value.channelId, returnDetails.value.returnId);

        await orderStore.updateRefundStatus(returnDetails.value.returnId, RefundStatusEnum.Waiting);
        returnDetails.value.refundStatus == RefundStatusEnum.Waiting;

        savedHash.value = hashComponentState.value;
      } catch (error) {
        showError(error as string, "Error");
      } finally {
        await l.dismiss();
      }
    }

    const informWebShopMessage = computed( ()=> {
      let messageText = "Are you sure you want to inform webshop about return now? <br/><br/>Refund is also approved and will be done automatically when all products have been received by warehouse.<br/><br/>";

      if(returnDetails.value?.status === ReturnOrderStatusEnum.Requested)
        messageText += "Return request should be approved before informing webshop.<br/>(Webshop will be informed automatically when return is approved.)<br/>";

      return new IonicSafeString(messageText);
    });

    const completeReturnMessage = computed( ()=> {
      let messageText = "Are you sure you want to refund now? <br/><br/>";

      if(returnDetails.value?.status !== ReturnOrderStatusEnum.Returned)
        messageText += "Products have not been received by warehouse! <br/>Refund is done using the information provided by the customer. <br/>";

      return new IonicSafeString(messageText);
    });

    const approveReturnMessage = computed( ()=> {
      let messageText = "Are you sure you want to approve return now? <br/><br/>";

      messageText += "When approved, customer will be refunded for " + returnDetails.value?.refundedAmount?.toFixed(2) + returnDetails.value?.currency + " automatically when all products have been received by warehouse.<br/>";

      return new IonicSafeString(messageText);
    });

    const cancelReturnMessage = computed( ()=> {
      let messageText = "Are you sure you want to cancel this return? <br/><br/>";

      messageText += "Warning: Cancellation cannot be undone.<br/>";

      return new IonicSafeString(messageText);
    });

    const returnIsCancellable = computed(() => {
      if (!returnDetails.value) return false;

      return returnDetails.value.status === ReturnOrderStatusEnum.Requested ||
        returnDetails.value.status === ReturnOrderStatusEnum.Waiting ||
        returnDetails.value.status === ReturnOrderStatusEnum.Draft;
    });

    const completeReturnToEcom = async () => {
      if (!returnDetails.value) return;

      const l = await loading();
      try {
        returnDetails.value = await orderStore.completeReturnToEcom(returnDetails.value.channelId, returnDetails.value.returnId);
        savedHash.value = hashComponentState.value;
      } catch (error) {
        showError(error as string, "Error");
      } finally {
        await l.dismiss();
      }
    }

    const translateResult = async () => {
      if (!returnDetails.value) return;

      const l = await loading();
      try {

        const textToBeTranslated = returnDetails.value.result.split(" -AI translation- ")[0];

        if (textToBeTranslated.length === 0) return;

        const translations = await configStore.translate(textToBeTranslated, "en", returnDetails.value.language);
        const validTranslation = translations.filter(i => i.language === returnDetails.value?.language);
        if( validTranslation.length > 0)
          returnDetails.value.result = textToBeTranslated + " -AI translation- " + validTranslation[0].text;
      } catch (error) {
        showError(error as string, "Error");
      } finally {
        await l.dismiss();
      }
    }

    const translateCustomerComment = async () => {
      if (!returnDetails.value) return;

      const l = await loading();
      try {

        const textToBeTranslated = returnDetails.value.customerComments.split(" -AI translation- ")[0];

        if (textToBeTranslated.length === 0) return;

        const translations = await configStore.translate(textToBeTranslated, returnDetails.value.language, "en");
        const validTranslation = translations.filter(i => i.language === returnDetails.value?.language);
        if( validTranslation.length > 0)
          returnDetails.value.customerComments = textToBeTranslated + " -AI translation- " + validTranslation[0].text;
      } catch (error) {
        showError(error as string, "Error");
      } finally {
        await l.dismiss();
      }
    }

    const backToRequested = async () => {
      if (!returnDetails.value) return;

      const l = await loading();
      try {
        returnDetails.value = await orderStore.backToRequestedReturnOrder(returnDetails.value.channelId, returnDetails.value.returnId);
        savedHash.value = hashComponentState.value;
      } catch (error) {
        showError(error as string, "Error");
      } finally {
        await l.dismiss();
      }
    }

    const updateRefundStatus = async (status: RefundStatusEnum) => {
      if (!returnDetails.value) return;

      const l = await loading();
      try {
        await orderStore.updateRefundStatus(returnDetails.value.returnId, status);
        returnDetails.value.refundStatus = status;
      } catch (error) {
        showError(error, "Error");
      } finally {
        await l.dismiss();
      }
    }

    //#region isDirty check for relative simple components
    const savedHash: Ref<number> = ref(0);
    const hashComponentState = computed(() => {
      return stringToHash(JSON.stringify({
        return: returnDetails.value,
      }));
    });

    const isDirty = computed(() => {
      if(savedHash.value === 0)
                return false;   
      return savedHash.value !== hashComponentState.value;
    });
    //#endregion

    const save = async () => {
      if (!returnDetails.value) return;

      const l = await loading();
      try {

        const returnOrderUpdate = new ReturnOrderUpdateDto();
        returnOrderUpdate.refundedAmount = returnDetails.value.refundedAmount;
        returnOrderUpdate.result = returnDetails.value?.result;
        returnOrderUpdate.customerComments = returnDetails.value?.customerComments;
        returnOrderUpdate.internalNote = returnDetails.value?.internalNote;
        returnOrderUpdate.refundType = returnDetails.value?.refundType;

        await updateRefundStatus(returnDetails.value.refundStatus);
        returnDetails.value = await orderStore.updateReturnOrder(returnDetails.value.channelId, returnDetails.value.returnId, returnOrderUpdate);
        savedHash.value = hashComponentState.value;
      } catch (error) {
        showError(error, "Error");
      } finally {
        await l.dismiss();
      }
    }

    const ShowPicture = (photo: PhotoLink) => {
      window.open(photo.url, "_blank");
    }

    const setShowCreateButtonValue = (value: boolean) => {
      showCreateShipmentButton.value = !value;
    }

    const createShipment = async () => {
      if (!returnDetails.value) return;

      const l = await loading();
      try {
        await orderStore.createShipmentFromReturn(returnDetails.value.channelId, returnDetails.value.returnId);
      } catch (error) {
        showError(error, "Error");
      } finally {
        await l.dismiss();
      }
    }

    const openTrackingPage = () => {
      if (!returnDetails.value?.token) return;
      const trackingPageUrl = `${getTrackingDomain()}/r/${returnDetails.value.token}`;
      window.open(trackingPageUrl, '_blank');
    }

    return {
      returnId, returnDetails, momentjs, openOrder, openExchangeOrder,
      refundOptions,
      isDirty, save, ReturnOrderStatusEnum, ShowPicture,
      closeReturn, backToRequested,
      isPhotoGalleryOpen,
      messages, emailPreviewOpen, previewMessage,
      cloudDoneOutline, sendOutline, mailUnreadOutline, mailOpenOutline, sadOutline,
      translateResult, translateCustomerComment,
      feedback, fromEcom, EcomReturnStatusEnum, alertResult, refundTypeOptions,
      informWebShopMessage, completeReturnMessage, approveReturnMessage, cancelReturnMessage, returnIsCancellable, totalReturnedProductCost,
      isAdmin, showCreateShipmentButton, setShowCreateButtonValue, createShipment, openTrackingPage, openMergedReturn
    }
  }
});
