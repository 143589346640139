
import { IonPage, IonContent, } from '@ionic/vue';
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import AdminMenu from "@/views/Admin/AdminMenu.vue";

import { useAuthStore } from '@/store/authStore';
import { defineComponent, computed } from 'vue';

import InvoicingDetailsComponent from '@/views/Invoice/InvoicingDetailsComponent.vue';

export default defineComponent({
  name: 'CostDetailsAdminsPage',
  components: { 
    PageHeader,
    PageFooter,
    IonPage, 
    IonContent,
    AdminMenu,
    InvoicingDetailsComponent,
  },

  setup () {
    const auth = useAuthStore();
    const adminsRole = computed(()=>auth.filteredRoles.includes("Admins"));

    return { adminsRole, }
  }
});

