import { defineStore } from 'pinia'
import { prettyResponse, getNow, patchNow, getFileNow, putNow, postNow, ogoServices } from './index';
import { AxiosResponse, CancelTokenSource } from 'axios';
import { ReturnDashboardDataDto, OrderEmissionDto, SelectedPeriod } from '@/models/orderService/Dashboard';
import { RefundStatusEnum, ReturnDetailedViewDto } from '@/models/orderService/Return';
import { ReturnDeliveryTypeDto, ReturnDeliveryTypeUpdateDto } from '@/models/orderService/DeliveryTypes';
import { RemovableRef, StorageSerializers, useLocalStorage } from '@vueuse/core';
import { ListViewState, ListDefinitions, SearchDefinition } from '@/models/orderService/ReturnsListFilter';
import { ReturnOrderDto, ReturnOrderUpdateDto, ReturnOrderWithActionsDto } from '@/models/orderService/ReturnOrder';
import { eventBus } from '@/composables/useOgoEvents';
import { ShipmentDto } from '@/models/trackingService/Shipment';
import { PurchaseOrderDto } from '@/models/orderService/Fabrikator';
import { StockUpdateResponse } from '@/models/orderService/StockUpdate';

interface OrderState {
  returnsListPage: RemovableRef<ListViewState>;
  returnOrderListPage: RemovableRef<ListViewState>;
}

export const useOrderStore = defineStore('order', {
  state: (): OrderState => ({
    returnsListPage: useLocalStorage("returnsListPage", new ListViewState(), { serializer: StorageSerializers.object,  mergeDefaults: true  }),
    returnOrderListPage: useLocalStorage("returnOrderListPage", new ListViewState(), { serializer: StorageSerializers.object,  mergeDefaults: true  }),
  }),

  getters: {
  }, 

  actions: {
    async getReturnDashboard(startDate: Date|undefined, endDate: Date|undefined, warehouseId: string|undefined) {
      const params = new URLSearchParams();    
      if(startDate){ params.append('startDate',startDate.toISOString()); }
      if(endDate){ params.append('endDate',endDate.toISOString()); }
      if(warehouseId){ params.append('warehouseId',warehouseId); }

      try {
          const r = await getNow(`returns/dashboard`,ogoServices.os, params) as AxiosResponse<ReturnDashboardDataDto>;
          return r.data;
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }
    },

    async getReturn(id: string) {
      try {
          const r = await getNow(`returns/${id}`,ogoServices.os, undefined) as AxiosResponse<ReturnDetailedViewDto>;
          return r.data;
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }
    },

    async getReturnDeliveryTypes() {
      try {
          const r = await getNow(`ReturnDeliveryTypes`,ogoServices.os, undefined) as AxiosResponse<Array<ReturnDeliveryTypeDto>>;
          return r.data;
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }
    },

    async getAllReturnDeliveryTypes(showRemoved: boolean|undefined, showHidden: boolean|undefined) {
      try {

        const params = new URLSearchParams();    
        if(showRemoved !== undefined){ params.append('showRemoved',showRemoved.toString()); }
        if(showHidden !== undefined){ params.append('showHidden',showHidden.toString()); }

          const r = await getNow(`ReturnDeliveryTypes/GetAll`,ogoServices.os, params) as AxiosResponse<Array<ReturnDeliveryTypeDto>>;
          return r.data;
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }
    },
    async getReturnDeliveryType(id:string) {
      try {
          const r = await getNow(`ReturnDeliveryTypes/${id}`,ogoServices.os, undefined) as AxiosResponse<ReturnDeliveryTypeDto>;
          return r.data;
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }
    },

    async updateReturnDeliveryType(id:string, data:ReturnDeliveryTypeUpdateDto) {
      try {
          const r = await putNow(`ReturnDeliveryTypes/${id}`,ogoServices.os, data) as AxiosResponse<ReturnDeliveryTypeDto>;
          return r.data;
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }
    },

    async createReturnDeliveryType(data:ReturnDeliveryTypeDto) {
      try {
          const r = await postNow(`ReturnDeliveryTypes/`,ogoServices.os, data) as AxiosResponse<ReturnDeliveryTypeDto>;
          return r.data;
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }
    },

    async updateRefundStatus(id: string, status: RefundStatusEnum) {
      // try {
          const r = await patchNow(`returns/${id}/refundStatus/${status}`,ogoServices.os, undefined) as AxiosResponse;
          eventBus.emit('returnChanged', r.data);
      // } catch (reason) {
          // throw (prettyResponse(reason,undefined));
      // }
    },

    async getCo2Dashboard(selectedPeriod: SelectedPeriod|undefined, startDate: Date|undefined, endDate: Date|undefined, warehouseId: string|undefined){
      try {
        const params = new URLSearchParams();    
        if(startDate){ params.append('startDate',startDate.toISOString()); }
        if(endDate){ params.append('endDate',endDate.toISOString()); }
        if(warehouseId){ params.append('warehouseId',warehouseId); }
        if(selectedPeriod !== undefined){ params.append('selectedPeriod',selectedPeriod.toString()); }
          const r = await getNow(`emission`, ogoServices.os, params ) as AxiosResponse<OrderEmissionDto>;
          return r.data; 
      } catch(reason){
        throw (prettyResponse(reason, undefined));
      }
    },

    async getCo2DummyDashboard(){
      try {   

          const r = await getNow(`emission/dummydata`, ogoServices.os, undefined ) as AxiosResponse<OrderEmissionDto>;
          return r.data; 
      } catch(reason){
        throw (prettyResponse(reason, undefined));
      }
    },

    async getShipmentDashboardReport(timeFrame: string|undefined, warehouseId: string|undefined, ShippingMethod: string|undefined, destinationCountry: string|undefined ){
      try {
        const params = new URLSearchParams(); 
        if(timeFrame){ params.append('timeFrame',timeFrame); }
        if(warehouseId){ params.append('warehouseId',warehouseId); }
        if(ShippingMethod){ params.append('ShippingMethod',ShippingMethod); }
        if(destinationCountry){ params.append('destinationCountry',destinationCountry); }
        const r = await getFileNow(`shipment/report`, ogoServices.os, params );
        return r.data;
      } catch (reason){
        throw (prettyResponse(reason, undefined));
      }
    },

    async getReturnDashboardReport(startDate: Date|undefined, endDate: Date|undefined, warehouseId: string|undefined){
      try {
        const params = new URLSearchParams(); 
        if(startDate){ params.append('startDate', startDate.toISOString()); }
        if(endDate){ params.append('endDate', endDate.toISOString()); }
        if(warehouseId){ params.append('warehouseId', warehouseId); }
        const r = await getFileNow(`returns/report`, ogoServices.os, params );
        return r.data;
      } catch (reason){
        throw (prettyResponse(reason, undefined));
      }
    },

    async getReturnOrderDefinitions(){
      try {
        const r = await getNow(`returns/returnorder/definitions`, ogoServices.os, undefined ) as AxiosResponse<ListDefinitions>;
        return r.data;
      } catch (reason){
        throw (prettyResponse(reason, undefined));
      }
    },

    async getReturnOrders(channelId: number, payload: SearchDefinition, cancelToken?: CancelTokenSource){
        const r = await postNow(`returns/${channelId}/search`, ogoServices.os, payload, undefined, cancelToken ) as AxiosResponse<Array<ReturnOrderWithActionsDto>>;
        return r.data;
    },

    async runReturnAction(channelId: number, returns: Array<ReturnOrderDto>, action: string){
        const r = await postNow(`returns/${channelId}/action/${action}`, ogoServices.os, returns ) as AxiosResponse<Array<ReturnOrderWithActionsDto>>;
        return r.data;
    },

    async getReturnOrder(channelId: number, returnOrderId: string){
      try {
        const r = await getNow(`returns/${channelId}/return/${returnOrderId}`, ogoServices.os, undefined ) as AxiosResponse<ReturnOrderDto>;
        return r.data;
      } catch (reason){
        throw (prettyResponse(reason, undefined));
      }
    },

    async updateReturnOrder(channelId: number, returnOrderId: string, data: ReturnOrderUpdateDto){
      try {
        const r = await patchNow(`returns/${channelId}/return/${returnOrderId}`, ogoServices.os, data ) as AxiosResponse<ReturnOrderDto>;
        eventBus.emit('returnChanged', r.data);
        return r.data;
      } catch (reason){
        throw (prettyResponse(reason, undefined));
      }
    },

    async approveReturnOrder(channelId: number, returnOrderId: string){
      try {
        const r = await getNow(`returns/${channelId}/approve/${returnOrderId}`, ogoServices.os, undefined ) as AxiosResponse<ReturnOrderDto>;
        eventBus.emit('returnChanged', r.data);
        return r.data;
      } catch (reason){
        throw (prettyResponse(reason, undefined));
      }
    },

    async cancelReturnOrder(channelId: number, returnOrderId: string){
      try {
        const r = await getNow(`returns/${channelId}/cancel/${returnOrderId}`, ogoServices.os, undefined ) as AxiosResponse<ReturnOrderDto>;
        eventBus.emit('returnChanged', r.data);
        return r.data;
      } catch (reason){
        throw (prettyResponse(reason, undefined));
      }
    },

    async closeReturnOrder(channelId: number, returnOrderId: string){
      try {
        const r = await getNow(`returns/${channelId}/close/${returnOrderId}`, ogoServices.os, undefined ) as AxiosResponse<ReturnOrderDto>;
        eventBus.emit('returnChanged', r.data);
        return r.data;
      } catch (reason){
        throw (prettyResponse(reason, undefined));
      }
    },

    async backToRequestedReturnOrder(channelId: number, returnOrderId: string){
      try {
        const r = await getNow(`returns/${channelId}/requested/${returnOrderId}`, ogoServices.os, undefined ) as AxiosResponse<ReturnOrderDto>;
        eventBus.emit('returnChanged', r.data);
        return r.data;
      } catch (reason){
        throw (prettyResponse(reason, undefined));
      }
    },

    async createReturnToEcom(channelId: number, returnOrderId: string){
      // try {
        const r = await getNow(`returns/${channelId}/ecom/${returnOrderId}/create`, ogoServices.os, undefined ) as AxiosResponse<ReturnOrderDto>;
        eventBus.emit('returnChanged', r.data);
        return r.data;
      // } catch (reason){
        // throw (prettyResponse(reason, undefined));
      // }
    },

    async completeReturnToEcom(channelId: number, returnOrderId: string){
      // try {
        const r = await getNow(`returns/${channelId}/ecom/${returnOrderId}/complete`, ogoServices.os, undefined ) as AxiosResponse<ReturnOrderDto>;
        eventBus.emit('returnChanged', r.data);
        return r.data;
      // } catch (reason){
      //   throw (prettyResponse(reason, undefined));
      // }
    },

    async createShipmentFromReturn(channelId: number, returnOrderId: string){
      try {
        const r = await getNow(`returns/${channelId}/return/${returnOrderId}/shipment`, ogoServices.os, undefined ) as AxiosResponse<ShipmentDto>;
        eventBus.emit('shipmentChanged', r.data);
        return r.data;
      }  catch (reason){
        throw (prettyResponse(reason, undefined));
      }
    }, 

    async getFabrikatorPurchaseOrders(merchantId: string, channelId: number){
        const r = await getNow(`fabrikator/${merchantId}/${channelId}`, ogoServices.os, undefined ) as AxiosResponse<Array<PurchaseOrderDto>>;
        return r.data;
    },

    async getFabrikatorPurchaseOrder(merchantId: string, channelId: number, id: number){
      const r = await getNow(`fabrikator/${merchantId}/${channelId}/${id}`, ogoServices.os, undefined ) as AxiosResponse<PurchaseOrderDto>;
      return r.data;
    },

    async generateStockUpdateFromFabrikatorPurchaseOrder(merchantId: string, channelId: number, id: number){
      const r = await getNow(`fabrikator/${merchantId}/${channelId}/${id}/StockUpdate`, ogoServices.os, undefined ) as AxiosResponse<StockUpdateResponse>;
      return r.data;
    },

    async mergeReturnOrders(channelId: number, returnOrderIds: Array<string>){
      const r = await postNow(`returns/${channelId}/merge`, ogoServices.os, returnOrderIds ) as AxiosResponse<ReturnOrderDto>;
      eventBus.emit('returnChanged', r.data);
      return r.data;
    }

  }
})