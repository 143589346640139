import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "page-layout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_page_description = _resolveComponent("page-description")!
  const _component_FabrikatorPO = _resolveComponent("FabrikatorPO")!
  const _component_page_footer = _resolveComponent("page-footer")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, {
    class: "ion-page",
    id: "main"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_page_header, { pageName: "Purchase orders" }),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_page_description, {
              pageName: "Import purchase orders",
              pageDescription: "Special ways of importing purchase orders",
              breadCrumbs: [{title: 'Purchase Orders', active: true, url: '/inbound/purchaseorders'}]
            }),
            _createVNode(_component_FabrikatorPO),
            _createVNode(_component_page_footer)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}