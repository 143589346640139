
import { computed, defineComponent, onMounted, Ref, ref } from 'vue';
import { IonButton, IonSelect, IonSelectOption, IonItem, IonList, IonLabel, IonText, IonCol, IonTitle, toastController, loadingController } from '@ionic/vue';
import { useTrackingStore } from '@/store/trackingStore';
import { MerchantIssueUpdateDto, ShipmentDto, ShipmentIssueEnum } from '@/models/trackingService/Shipment';
import * as moment from 'moment-timezone';
import { useAuthStore } from '@/store/authStore';
import { useErrorBox } from './errorBox';
import { stringToHash } from '@/utils/changeTracking';
import { useOgoEvents } from '@/composables/useOgoEvents';


export default defineComponent({
    name: "TrackingEvents",
    props: {
        shipmentId: String,
        channelId: Number,

    },
    emits: {
    trackingNotCreated: (_created: boolean) => true,
  },
    components: {
        IonButton,
        IonSelect,
        IonItem,
        IonList,
        IonLabel,
        IonText,
        IonCol,
        IonTitle,
        IonSelectOption,
    },
    setup(props, emits) {

        const trackingStore = useTrackingStore();
        const auth = useAuthStore();
        const shipmentDetails = ref<ShipmentDto | undefined>(undefined);
        const { showError } = useErrorBox();

        const momentjs: any = moment;
        moment.locale(auth.locale);

        const issueOptions = [
            { id: ShipmentIssueEnum.None, title: "None" },
            { id: ShipmentIssueEnum.NewIssue, title: "Unhandled issue" },
            { id: ShipmentIssueEnum.Investigate, title: "Investigate" },
            { id: ShipmentIssueEnum.Solved, title: "Solved" },
            { id: ShipmentIssueEnum.Ignore, title: "Ignore" },
        ];

        const initialize = async () => {
            shipmentDetails.value = await trackingStore.getShipment(props.shipmentId ?? "", props.channelId ?? 0);
            emits.emit('trackingNotCreated', true);
        }

        useOgoEvents({ shipmentChanged: initialize });

        onMounted(async () => {
            try {
                shipmentDetails.value = await trackingStore.getShipment(props.shipmentId ?? "", props.channelId ?? 0);
                savedHash.value = hashComponentState.value;
            } catch (error) {
                emits.emit('trackingNotCreated', false);
            }
        });

        const refresh = async () => {
            if (!shipmentDetails.value)
                return;
            const l = await loading();
            try {
                await trackingStore.getTracking(shipmentDetails.value.uid, shipmentDetails.value.channelId);
                shipmentDetails.value = await trackingStore.getShipment(shipmentDetails.value.uid, shipmentDetails.value.channelId);
            } catch (error) {
                showError(error, "Error");
            } finally {
                l.dismiss();
            }
        }

        const loading = async () => {
            const loading = await loadingController.create({
                cssClass: "my-custom-class",
                message: "Processing...",
                duration: undefined,
            });

            await loading.present();
            return loading;
        };

        const save = async () => {
            if (!auth.merchantId || !auth.channelId || !shipmentDetails.value)
                return;

            try {

                const issue = new MerchantIssueUpdateDto(shipmentDetails.value.merchantIssue);

                await trackingStore.saveMerchantIssue(shipmentDetails.value.channelId, shipmentDetails.value.uid, issue);

                savedHash.value = hashComponentState.value;

                const toast = await toastController.create({
                    message: "Tracking issue saved successfully!",
                    duration: 1500,
                    position: "top",
                    color: "primary",
                });

                await toast.present();

            } catch (e) {
                showError(e as string, "Error saving settings!");
            }
        }

        //#region isDirty check for relative simple components
        const savedHash: Ref<number> = ref(0);
        const hashComponentState = computed(() => {
            return stringToHash(JSON.stringify({
                merchantIssue: shipmentDetails.value?.merchantIssue,
            }));
        });

        const isDirty = computed(() => {
            return savedHash.value !== hashComponentState.value;
        });


        return {
            shipmentDetails, issueOptions, momentjs, refresh, save, isDirty
        }
    }
});
