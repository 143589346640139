
import { IonTitle, IonGrid,IonRow,IonCol,IonList,IonItem,IonToggle, ToggleCustomEvent, } from '@ionic/vue';

import { defineComponent, onMounted, ref, Ref } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/store/authStore';
import { useMerchantStore } from '@/store/merchantStore';
import { useConfigStore } from '@/store/configStore';
import { ClaimReasonDto } from '@/models/configService/ClaimReasons';

class ClaimReasonViewModel  
{
    id: string;
    code: string;
    text: string;
    enabled: boolean;
    isRemoved: boolean;

    constructor(dto: ClaimReasonDto, locale: string, enabled: boolean){
        this.id = dto.id;
        this.code = dto.code;
        this.text = dto.localizations.find(l=>l.lang === locale)?.context ?? 
                    dto.localizations.find(l=>l.lang === "en")?.context ?? 
                    "";
        this.enabled = enabled;
        this.isRemoved = dto.isRemoved;
    }
}

export default defineComponent({
  name: 'ClaimReasons',
  components: {
    IonGrid,
    IonRow,
    IonCol, 
    IonList,IonItem,
    IonToggle,
    IonTitle,
  },
  data() {
    return {
      chartOptions: {
        responsive: true
      }
    }
  },
  setup (){
    const router = useRouter();
    const auth = useAuthStore();
    const merchantStore = useMerchantStore();
    const configStore = useConfigStore();
    const claimReasons: Ref<Array<ClaimReasonViewModel>> = ref([]);

    let allReasons:Array<ClaimReasonDto> = [];

    const updateClaimReasons = async (active: string[]) => {
      claimReasons.value = allReasons.map((val)=> {
        return new ClaimReasonViewModel(val,"en", active.indexOf(val.id) >= 0);
      }).sort((i,j)=>{
        if (i.text < j.text) { return -1; }
        if (i.text > j.text) { return 1; }
        return 0;      
      });
      claimReasons.value = claimReasons.value.filter(i=>!i.isRemoved ||  (i.enabled && i.isRemoved));
    }

    onMounted(async () => {
      if(!auth.merchantId || !auth.channelId)
        return;

      allReasons = await configStore.getChannelClaimReasons(auth.channelId);
      const active = await merchantStore.getEnabledClaimReasons(auth.merchantId, auth.channelId);
      await updateClaimReasons(active);        
    });

    const wasChanged = async (_a: ToggleCustomEvent, _b:ClaimReasonViewModel) =>{
      if(!auth.merchantId || !auth.channelId)
        return;
      
      const idList = claimReasons.value.filter(i=>i.enabled).map(i=>i.id);
      const active = await merchantStore.updateEnabledClaimReasons(auth.merchantId, auth.channelId, idList);
      await updateClaimReasons(active);
    }

  return {
    claimReasons,router,wasChanged,
  }
}
});

