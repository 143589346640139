
  import { IonTitle, IonPage, IonItem, IonGrid, 
    IonRow, IonCol, IonButton, IonContent,
    IonSelect, IonSelectOption, IonInput,
    IonText,
IonToggle,
  } from '@ionic/vue';
  import { Ref, computed, defineComponent, onMounted, ref, watch } from 'vue';
  import { ActionTypes, useStore } from '@/store';
  import { onBeforeRouteLeave, useRouter } from 'vue-router';

  import PageHeader from "@/components/PageHeader.vue";
  import PageFooter from "@/components/PageFooter.vue";
  import PageDescription from '@/components/PageDescription.vue';

  import * as moment from 'moment-timezone';
  import { useErrorBox } from '@/components/errorBox';
  import { CurrencyOption } from '@/models/configService/Currency';
  import { CountryDto } from '@/models/configService/Country';
  import { ReturnRuleDto } from '@/models/merchantService/Returns';
  import { WarehouseDto } from '@/models/warehouseService/Warehouse';
  import { ReturnDeliveryTypeViewModel } from './ReturnDeliveryTypes.vue';
import { useAuthStore } from '@/store/authStore';
import { useConfigStore } from '@/store/configStore';
import { useOrderStore } from '@/store/orderStore';
import { useMerchantStore } from '@/store/merchantStore';
import { stringToHash } from '@/utils/changeTracking';

  export default defineComponent({
    name: 'ReturnRuleDetails',
    components: { 
      IonPage,
      IonTitle,
      IonText,
      IonItem, 
      IonInput,
      IonGrid, IonRow, IonCol, IonContent,
      IonSelect, IonSelectOption,
      IonButton,
      IonToggle,
      PageHeader,
      PageFooter,
      PageDescription,
    },
    setup(){    
      const store = useStore();
      const router = useRouter();
      const auth = useAuthStore();
      const config = useConfigStore();
      const orderStore = useOrderStore();
      const merchantStore = useMerchantStore();
      
      const { showError } = useErrorBox();

      const momentjs: any = moment;
      moment.locale(store.state.locale ?? "en");

      const returnDetails: Ref<ReturnRuleDto|undefined> = ref(undefined);

      const currencies: Ref<Array<CurrencyOption>> = ref([]);
      const countries: Ref<Array<CountryDto>> = ref([]);
      const warehouses: Ref<Array<WarehouseDto>> = ref([]);
      const returnDeliveryTypes: Ref<Array<ReturnDeliveryTypeViewModel>> = ref([]);

      const countryAndWarehouse = computed(() => {
        if(returnDetails.value && returnDetails.value.country && returnDetails.value.warehouse){ 
          return `${returnDetails.value.country} - ${returnDetails.value.warehouse}`;}
        return "";
      });
      
      onMounted( async() => {
        if(!auth.merchantId || !auth.channelId){
          return;
        }

        const id = router.currentRoute.value.params["id"].toString();
        if(id) {
          try {
            returnDetails.value = await merchantStore.getMerchantReturnRule(auth.merchantId,auth.channelId,id);
          } catch (error) {
            showError(error, "Error");
            router.back();
          }
        } else {
          returnDetails.value = new ReturnRuleDto();
        }
        savedHash.value = hashComponentState.value;

        currencies.value = await config.getCurrencies();
        countries.value = await config.getCountries();
        warehouses.value = await store.dispatch(ActionTypes.WS_GET_WAREHOUSES, undefined);

      });

      onBeforeRouteLeave(() => {
        if(isDirty.value)
          return window.confirm('You have unsaved changes! Do you want to leave?');
        
        return true;
      });


      watch(countryAndWarehouse, async (_newVal, _oldVal) => {
        if(returnDetails.value && _newVal !== ""){
              const dto = await orderStore.getReturnDeliveryTypes();
              returnDeliveryTypes.value = dto
              .filter(a=>{
                const country = a.availableCountries.find(i=>i.countryCode === returnDetails.value?.country);
                if(!country) return false;
                return country.warehouses.indexOf(returnDetails.value?.warehouse ?? "-") >= 0;})
              .map((val)=> {
                return new ReturnDeliveryTypeViewModel(val, store.getters.locale ?? "en");})
              .sort((i,j)=>{
                if(i.indexNumber !== j.indexNumber)
                return i.indexNumber - j.indexNumber;
            
                if (i.title < j.title) { return -1; }
                if (i.title > j.title) { return 1; }
                return 0;      
              });
            }
    });

    const canBeSaved = computed(() => {
      if(!returnDetails.value)
      return false;

      if(countryAndWarehouse.value !== "" && returnDetails.value.id === undefined)
        return true;

        if(countryAndWarehouse.value !== "" && 
        returnDetails.value.id !== undefined && 
        returnDetails.value.currency !== undefined && 
        returnDetails.value.fee !== undefined && 
        returnDetails.value.deadline >= 0  && 
        returnDetails.value.deliveryTypes !== undefined)
        return true;

      return false;
    });

    const savedHash: Ref<number> = ref(0);
    const hashComponentState = computed(() => {
      return stringToHash(JSON.stringify({
        return: returnDetails.value,
      }));
    });

    const isDirty = computed(() => {
      if(savedHash.value === 0)
                return false;   
      return savedHash.value !== hashComponentState.value;
    });

    const save = async () => {
      if(!returnDetails.value)
        return false;

        if(!auth.merchantId || !auth.channelId)
        return false;
      
      try {
        if(returnDetails.value.id){
          returnDetails.value = await merchantStore.updateMerchantReturnRule(auth.merchantId,auth.channelId,returnDetails.value);
        } else {
          const r = await merchantStore.addMerchantReturnRule(auth.merchantId,auth.channelId, returnDetails.value);
          router.replace({name: "ReturnRuleDetails", params: {id: r.id}});
        }
      } catch (error) {
        showError(error, "Error");
      }
      savedHash.value = hashComponentState.value;
      return false;
      }

    return {
      returnDetails, momentjs, isDirty,
      currencies, countries, warehouses,returnDeliveryTypes,canBeSaved,save,countryAndWarehouse,}
    }
  });
