import { defineStore } from 'pinia'
import { prettyResponse, getAnyService } from './index';
import { AxiosResponse } from 'axios';
import { serviceDescription } from '@/models/serviceStatus/ServiceDescription';
import { PackageList } from '@/models/serviceStatus/packageList';


export const ogo2Services:string[] = ["config", "ecom", "ws" , "ns" , "ms" , "os" , "ts" , "files", "pps", "fbs", "ps"];

export let serviceDict:{[id:string]: serviceDescription }
export let packageList:{[id:string]: PackageList}

export const useServiceStatusStore = defineStore('serviceStatus', {
  state: () => ({
    services: serviceDict,
    packageList: packageList,
    //languages: undefined as LanguageDto[] | undefined,
  }),

  getters: {
  }, 

  actions: {
    async getServices() {
        this.services = {};
        try {
            ogo2Services.forEach(async i => {
                this.services[i] = new serviceDescription(i, "", "");
                const res = await getAnyService(`health`, i, undefined) as AxiosResponse<string>;
                this.services[i] =  new serviceDescription(i, "", res.data);
        })
          return;
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }
    },

    async getPackages() {
        this.packageList = {};
        try {
            ogo2Services.forEach(async i => {
              const packageName = i;
              // this.packageList[packageName] = {version: 0, parameters: ""};
              getAnyService(`packagelist.json`, i, undefined) 
                .then(res => { 
                  this.packageList[packageName] = res.data
                }).catch(reason => {
                  //this.packageList[packageName] = {version: 0, parameters: ""};
                });
            });
        } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }
    },

  }
})