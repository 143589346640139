
import { computed, defineComponent, onMounted, watch, } from 'vue';
import {IonPage, IonContent} from '@ionic/vue';
import { storeToRefs } from 'pinia';

import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import PageDescription from '@/components/PageDescription.vue';

import { useAuthStore } from '@/store/authStore';

import FabrikatorPO from '@/views/PurchaseOrder/FabrikatorPO.vue';

export default defineComponent({
  name: 'PurchaseOrdersPage',
  components: {
    PageHeader,
    PageFooter,
    PageDescription,
    IonPage, 
    IonContent,
    FabrikatorPO,
  },
  setup (){
    const auth = useAuthStore();
    const isAdmin = computed(() => { return auth.filteredRoles.includes("Admins"); });    
    const { merchantId, channelId } = storeToRefs(auth);
    

    const initialize = async ()=> {
      if(!merchantId.value || !channelId.value)
        return;
    }

    onMounted(initialize);
    watch(channelId, initialize);

  return { 
    isAdmin, };
}
});

