
import { IonPage, IonContent, } from '@ionic/vue';
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import DashboardMenu from '@/views/Dashboard/DashboardMenu.vue';

import { computed, defineComponent, } from 'vue';

import InvoicingDetailsComponent from '@/views/Invoice/InvoicingDetailsComponent.vue';
import ModalHelpButton from '@/components/ModalHelpButton.vue';
import { useAuthStore } from '@/store/authStore';

export default defineComponent({
  name: 'CostDetailsDashboardPage',
  components: { 
    PageHeader,
    PageFooter,
    IonPage, 
    IonContent,
    DashboardMenu,
    InvoicingDetailsComponent,
    ModalHelpButton,
  },

  setup () {
    const auth = useAuthStore();
    const hasInvoiceReadScope = computed(() => auth.filteredScopes.includes("read:invoices"));
    return {hasInvoiceReadScope}
  }
});

