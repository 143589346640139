
import { IonContent, IonSelect, IonSelectOption, loadingController, IonRow, IonCol, IonCheckbox, IonModal, IonToolbar, IonTitle, IonButton, IonButtons, } from '@ionic/vue';

import { Ref, defineComponent, ref, computed, } from 'vue';

import TreeItem from '@/components/TreeItem.vue';
import { TreeItemSource } from '@/models/components';
import { onMounted } from 'vue';
import { useConfigStore } from '@/store/configStore';
import { CategoryWithCostsDto, InvoiceRowDetailed } from '@/models/configService/InvoicingMetrics';
import { ActionTypes, useStore } from '@/store';
import { WarehouseDto } from '@/models/warehouseService/Warehouse';
import { watch } from 'vue';
import { useErrorBox } from '@/components/errorBox';
import { useAuthStore } from '@/store/authStore';
import { stringToHash } from '@/utils/changeTracking';

import moment from 'moment-timezone';

export default defineComponent({
  name: 'InvoicingDetailsComponent',
  components: { 
    IonContent,
    IonSelect,
    IonSelectOption,    
    IonCheckbox,
    // IonGrid,
    IonRow,
    IonCol,
    TreeItem,
    IonModal,
    IonToolbar,
    IonTitle,
    IonButton,
    IonButtons,
  },
  props: {
    showAdminData: {default: false, type: Boolean},
  },

  setup (props) {

    const momentjs = (a: any): moment.Moment => { return moment(a); }
    const formatter = new Intl.NumberFormat('fi-FI', { style: 'currency', currency: 'EUR' });

    const months = [
      {id: 1, name: "January (1)"},
      {id: 2, name: "February (2)"},
      {id: 3, name: "March (3)"},
      {id: 4, name: "April (4)"},
      {id: 5, name: "May (5)"},
      {id: 6, name: "June (6)"},
      {id: 7, name: "July (7)"},
      {id: 8, name: "August (8)"},
      {id: 9, name: "September (9)"},
      {id: 10, name: "October (10)"},
      {id: 11, name: "November (11)"},
      {id: 12, name: "December (12)"},
    ];

    const years = [
      {id: 2024, name: "2024"},
    ];

    const prevmonth = momentjs(new Date()).utc(true).startOf('month').add(-1,'months').toDate();
    const fromMonthSelected: Ref<number> = ref(prevmonth.getMonth() + 1);
    const toMonthSelected:  Ref<number> = ref(prevmonth.getMonth() + 1);
    const fromYearSelected: Ref<number> = ref(prevmonth.getFullYear());
    const toYearSelected: Ref<number> = ref(prevmonth.getFullYear());


    const invoiceRowsModalOpen: Ref<boolean> = ref(false);
    const invoiceRows: Ref<Array<InvoiceRowDetailed>> = ref([]);
      
    const treeSource: Ref<Array<TreeItemSource>> = ref([]);
    const configStore = useConfigStore();
    const store = useStore();
    const auth = useAuthStore();

    const warehouses: Ref<Array<WarehouseDto>> = ref([]);
    const warehouseSelected: Ref<string|undefined> = ref(undefined);

    const adminsRole = computed(()=>auth.filteredRoles.includes("Admins") && props.showAdminData);

    const allMerchants: Ref<boolean> = ref(false);
    const allChannels: Ref<boolean> = ref(true);

    const { showError } = useErrorBox();
    
    const loading = async () => {
      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: "Processing...",
        duration: undefined,
      });

      await loading.present();
      return loading;
    };
      
    const init = async () => {
      const l = await loading();
      try {

        const firstPossible = momentjs(new Date(2024, 5, 1)).utc(true).startOf('day').toDate();

        const fromDate = momentjs(new Date(fromYearSelected.value, fromMonthSelected.value - 1, 1)).utc(true).startOf('day').toDate();
        const toDate = momentjs(new Date(toYearSelected.value, toMonthSelected.value - 1, 1)).utc(true).startOf('day').add(1, 'months').toDate();

        if (fromDate >= toDate)
          throw new Error("From date cannot be after to date");

        if(fromDate < firstPossible)
          throw new Error("Date range too far in the past");

        // console.log("fromDate", fromDate, "toDate", toDate, "warehouseSelected", warehouseSelected.value);

        treeSource.value = [];
        const categories = await configStore.getCategoriesWithCosts(fromDate, toDate, warehouseSelected.value, allMerchants.value, allChannels.value);
        categories.forEach(cat => {
          treeSource.value.push(CategoryWithCostsDto.getTreeItemSource(cat));
        });
      } catch (error) {
        await showError(error, "Error");
      } finally {
        l.dismiss();
      }
    }
    const showInvoiceRows = async (categoryidList:Array<string>, _dimension: string|undefined) => {
      const l = await loading();
      try {
        const fromDate = momentjs(new Date(fromYearSelected.value, fromMonthSelected.value - 1, 1)).utc(true).startOf('day').toDate();
        const toDate = momentjs(new Date(toYearSelected.value, toMonthSelected.value - 1, 1)).utc(true).startOf('day').add(1, 'months').toDate();

        if (fromDate >= toDate)
          throw new Error("From date cannot be after to date");

        const tmp = await configStore.getInvoiceRowsWithCosts(fromDate, toDate, warehouseSelected.value, categoryidList, allMerchants.value, allChannels.value);
        invoiceRows.value = tmp.sort((a,b) => a.description.localeCompare(b.description));
        invoiceRowsModalOpen.value = true;

      } catch (error) {
        await showError(error, "Error");
      } finally {
        l.dismiss();
      }
    }

    onMounted( async () => {
      warehouses.value = await store.dispatch(ActionTypes.WS_GET_WAREHOUSES, undefined);        
      await init();      
    });

    const hashComponentState = computed(() => {
      return stringToHash(JSON.stringify({
        warehouseSelected: warehouseSelected.value,
        fromMonthSelected: fromMonthSelected.value,
        toMonthSelected: toMonthSelected.value,
        fromYearSelected: fromYearSelected.value,
        toYearSelected: toYearSelected.value,
        allMerchants: allMerchants.value,
        allChannels: allChannels.value,
      }));
    });

    watch(hashComponentState, async (_) => {
      await init();
    }, {immediate: false});

    const totalAmount = computed(() => {
      return formatter.format(treeSource.value.reduce((acc, cat) => acc + cat.getTotalAmount(), 0));
    });

    const totalAltAmount = computed(() => {
      return formatter.format(treeSource.value.reduce((acc, cat) => acc + cat.getTotalAltAmount(), 0));
    });

    return {
      treeSource, warehouses, warehouseSelected,
      fromMonthSelected, toMonthSelected, fromYearSelected, toYearSelected, months, years, 
      allMerchants, allChannels, showInvoiceRows, invoiceRowsModalOpen, invoiceRows, auth,
      momentjs, totalAmount, totalAltAmount, adminsRole,      formatter,
    }
  }
});

