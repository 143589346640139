import { ShipmentTrackingStatus } from "./ShipmentMetrics";

export class ShipmentDto {
    uid = "";
    channelId = -1;
    warehouse = "";
    postCode?: string;
    countryCode?: string;
    coordinates?: Point;
    metaData: Record<string, string> = {};
    createdAt: Date = new Date();
    createdBy = "";
    shippedTimeStamp?: Date;
    transitTimeStamp?: Date;
    deliveryTimeStamp?: Date;
    deliveryWorkingDays?: number;
    latestEventTime?: Date;
    trackingStatus: ShipmentTrackingStatus = ShipmentTrackingStatus.NotSet;
    trackingStopReason?: TrackingStopReason;
    editedAt?: Date;
    nextCheck?: Date;
    merchantIssue: ShipmentIssueEnum = ShipmentIssueEnum.None;
    ogoIssue?: ShipmentIssueEnum;
    internalComment?: string;
    parcels: ParcelDto[] = [];
    shipmentType: OrderTypeEnum = OrderTypeEnum.SalesOrder;
}

export class Point {
    X?: number;
    Y?: number;
}

export enum OrderTypeEnum {
    SalesOrder = "SalesOrder",
    Return = "Return",
}

export enum TrackingStopReason {
    None = "None",
    NotNeeded = "NotNeeded",
    Timeout = "Timeout",
    Unknown = "Unknown",
    WebHook = "WebHook",
    Error = "Error",
}

export enum ShipmentIssueEnum
{
    None = "None",
    NewIssue = "NewIssue",
    Investigate = "Investigate",
    Solved = "Solved",
    Ignore = "Ignore",
}

export class MerchantIssueUpdateDto {
    MerchantIssue?: ShipmentIssueEnum
    constructor(issue: ShipmentIssueEnum){
        this.MerchantIssue = issue;
    }
}

export class OgoIssueUpdateDto {
    ogoIssue?: ShipmentIssueEnum;
    internalComment?: string;
}


export class ParcelDto {
    trackingCodes: TrackingCodeDto[] = [];
    trackingProvider = '';
    isDefault = false;
    trackingEvents: TrackingEventDto[] = [];
    previousCheck?: Date;
    trackingStatus?: ShipmentTrackingStatus;
    measurements?: ParcelMeasurements;
    estimatedDeliveryTime?: Date;
    url?: string;
    isLate = false;
}

export class TrackingEventDto {
    eventType: ShipmentTrackingStatus = ShipmentTrackingStatus.NotSet;
    timeStamp: Date = new Date();
    location = "";
    internalInfo?: string;
    message?: string;
    errorMessage?: string;
}
export class TrackingCodeDto {
    code = "";
    codeType: TrackingCodeType = TrackingCodeType.Unknown;
}

export enum TrackingCodeType {
    Removed = 0,
    Parcel = 1,
    ReturnParcel = 2,
    Shipment = 3,
    ReturnShipment = 4,
    Unknown = 10,
}

export class ParcelMeasurements {
    firstMm?: number;
    secondMm?: number;
    thirdMm?: number;
    weightInGrams?: number;
}

export class IsLateUpdateDto {
    isLate = false;
    trackingNumber: string | undefined;
}