
  import { IonGrid, IonRow, IonCol, IonButton, IonIcon, IonSelect, IonSelectOption, IonText } from '@ionic/vue';
  import { gridOutline, downloadOutline, flameOutline, } from "ionicons/icons";

  import { Bar, } from 'vue-chartjs'
  import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, 
    LinearScale, ArcElement, ChartData, PointElement, LineElement, ChartOptions, } from 'chart.js'
  import { defineComponent, onMounted, reactive, ref, Ref, watch, } from 'vue';
  import moment from 'moment-timezone';

  import { ReturnDashboardDataDto } from '@/models/orderService/Dashboard';
  import { useRouter } from 'vue-router';
  import { useErrorBox } from '@/components/errorBox';
  import { useAuthStore } from '@/store/authStore';
  import { useConfigStore } from '@/store/configStore';

  import { InvoiceDashboardResponse } from '@/models/configService/InvoicingMetrics';

  import { useUrlSearchParams } from '@vueuse/core';

  ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement, PointElement, LineElement, );
  
  export default defineComponent({
    name: 'InvoiceDashboardComponent',
    components: { 
      IonGrid,
      IonRow,
      IonCol, 
      IonButton,
      IonIcon,
      IonSelect,
      IonSelectOption,
      IonText,
      Bar,
    },
    setup (){
      const router = useRouter();
      const auth  = useAuthStore();
      const configStore = useConfigStore();
  
      const { showError } = useErrorBox();

      const momentjs = (a: any): moment.Moment => { return moment(a); }
      const formatter = new Intl.NumberFormat('fi-FI', { style: 'decimal', maximumFractionDigits: 0, });
      const formatterDecimals = new Intl.NumberFormat('fi-FI', { style: 'decimal', maximumFractionDigits: 2, });
  
      const data: Ref<ReturnDashboardDataDto|undefined> = ref(undefined);

      const optionsDataTotalCostsVsOrders: ChartOptions<'bar'> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: true, position: 'bottom', },
          title: { display: true, text: 'Total costs (EUR) and number of outgoing orders', },          
        },
        scales: {
          // x: {
          //   type: 'category',
          //   labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
          // },
          y1: {
            beginAtZero: true,
            title: { display: true, text: 'Costs (EUR)' },
            display:true,
            position: 'left',
          },
          y2: {
            beginAtZero: true,
            title: { display: true, text: 'Orders' },
            grid: { display: false, },
            display: true,
            position: 'right',
          }
        },
      };

      const dataTotalCostsVsOrders = ref<ChartData>({
        labels: [],
        datasets: [
          {
            type: 'bar',
            label: 'Total costs',
            data: [],
            borderColor: 'rgb(75, 192, 192)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            yAxisID: 'y1',
          },
          {
            type: 'line',
            label: 'Outgoing orders',
            data: [],
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            yAxisID: 'y2',
            pointRadius: 10,
          },
        ],
      });

      const optionsPerOrderAndPerSku: ChartOptions<'bar'> = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: { display: true, position: 'bottom', },
          title: { display: true, text: 'Cost per order and per SKU (EUR)', },          
        },
        scales: {
          y1: {
            beginAtZero: true,
            title: { display: true, text: 'Costs (EUR)' },
            display:true,
            position: 'left',
          },
        },
      };

      const dataPerOrderAndPerSku = ref<ChartData>({
        labels: [],
        datasets: [
          {
            type: 'line',
            label: 'Costs per order',
            data: [],
            borderColor: 'rgb(75, 192, 192)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            pointRadius: 10,
            yAxisID: 'y1',
          },
          {
            type: 'line',
            label: 'Average cost per SKU',
            data: [],
            borderColor: 'rgb(255, 99, 132)',
            backgroundColor: 'rgba(255, 99, 132, 0.2)',
            pointRadius: 10,
            yAxisID: 'y1',
          },
        ],
      });

      const checkReportLink: Ref<string|undefined> = ref(undefined); 
      const loadingReport: Ref<boolean> = ref(false); 

      const invoiceDashboardData: Ref<InvoiceDashboardResponse|undefined> = ref(undefined);
  
      const removeDays = (date: Date, days: number) => {
        const result = new Date(date)
        result.setDate(result.getDate() - days)
        return result
      }
  
      const params = useUrlSearchParams("history");

      const endDate: Ref<Date> = ref(new Date());
      const startDate: Ref<Date> = ref(removeDays(new Date(), 30));
  
      const months = [
      {id: 1, name: "January (1)"},
      {id: 2, name: "February (2)"},
      {id: 3, name: "March (3)"},
      {id: 4, name: "April (4)"},
      {id: 5, name: "May (5)"},
      {id: 6, name: "June (6)"},
      {id: 7, name: "July (7)"},
      {id: 8, name: "August (8)"},
      {id: 9, name: "September (9)"},
      {id: 10, name: "October (10)"},
      {id: 11, name: "November (11)"},
      {id: 12, name: "December (12)"},
    ];

    const years = [
      {id: 2024, name: "2024"},
    ];

    const firstmonth = momentjs(new Date()).utc(true).startOf('month').add(-4,'months').toDate();
    const lastmonth = momentjs(new Date()).utc(true).startOf('month').add(-1,'months').toDate();

    const getMonthName = (month: string) => {
      return  momentjs(month).month()+1;
    }

    const state = reactive ({
      fromMonth: firstmonth.getMonth() + 1,
      toMonth: lastmonth.getMonth() + 1,
      fromYear: firstmonth.getFullYear(),
      toYear: lastmonth.getFullYear(),
    });

    watch(state, async ()=>{
      // save state to url
      Object.keys(state).forEach((key) => {
        params[key] = JSON.stringify(state[key as keyof typeof state]);
      });

      await refreshCharts();
    });
    
    const refreshCharts = async () => {
      try {

        if(!state.fromYear || !state.fromMonth || !state.toYear || !state.toMonth)
          return;

        if(!auth.merchantId)
          return;

        const firstPossible = momentjs(new Date(2024, 5, 1)).utc(true).startOf('day').toDate();
        const fromDate = momentjs(new Date(state.fromYear, state.fromMonth - 1, 1)).utc(true).startOf('day').toDate();
        const toDate = momentjs(new Date(state.toYear, state.toMonth - 1, 1)).utc(true).endOf('month').toDate();

        if (fromDate >= toDate)
          throw new Error("From date cannot be after to date");

        if(fromDate < firstPossible)
          throw new Error("Date range too far in the past");

        // Reset data to hide graph
        dataTotalCostsVsOrders.value.datasets[0].data = [];      
        dataTotalCostsVsOrders.value.datasets[1].data = [];

        dataPerOrderAndPerSku.value.datasets[0].data = [];
        dataPerOrderAndPerSku.value.datasets[1].data = [];

        const resp = await configStore.getInvoiceDashboardData(fromDate, toDate, auth.merchantId);

        dataTotalCostsVsOrders.value.datasets[0].data = resp.months.map(m => m.totalCosts ?? 0);
        dataTotalCostsVsOrders.value.datasets[1].data = resp.months.map(m => m.totalOrders ?? 0);
        dataTotalCostsVsOrders.value.labels = resp.months.map(m => getMonthName(m.month));

        dataPerOrderAndPerSku.value.datasets[0].data = resp.months.map(m => (m.totalCosts ?? 0) / (m.totalOrders ?? 1));
        dataPerOrderAndPerSku.value.datasets[1].data = resp.months.map(m => (m.totalCosts ?? 0) / (m.totalRows ?? 1));
        dataPerOrderAndPerSku.value.labels = resp.months.map(m => getMonthName(m.month));

        invoiceDashboardData.value = resp;

      } catch (err) {
        await showError(err, "Error")
      }
      return;
    }
  
    onMounted(async () => {      
      // load state from url
      Object.keys(state).forEach((key) => {
        if(params[key])
          state[key as keyof typeof state] = JSON.parse(params[key] as string);
      });

      await refreshCharts();
    });

      const getReport = async () => {        
        loadingReport.value = true;
        try {

          const fromDate = momentjs(new Date(state.fromYear, state.fromMonth - 1, 1)).utc(true).startOf('day').toDate();
          const toDate = momentjs(new Date(state.toYear, state.toMonth - 1, 1)).utc(true).endOf('month').toDate();
  
          if (fromDate >= toDate)
            throw new Error("From date cannot be after to date");

          const res = await configStore.getInvoiceDashboardReport(fromDate, toDate, auth.merchantId);

          const FILE = window.URL.createObjectURL(new Blob([res]));
          checkReportLink.value = FILE;
          
        } catch (err) {
          await showError(err, "Error");
        } finally {
          loadingReport.value = false;
        }
      }
    
    return {
      data,router,
      startDate,endDate, checkReportLink, loadingReport, getReport,
      gridOutline, downloadOutline, flameOutline,
      months, years, state,
      optionsDataTotalCostsVsOrders, dataTotalCostsVsOrders, optionsPerOrderAndPerSku, dataPerOrderAndPerSku,
      invoiceDashboardData, getMonthName, formatter, formatterDecimals,
    }
  }
  });
  
  