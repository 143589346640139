
import {  IonPage, IonContent, IonGrid,IonRow,IonCol,IonButton, IonChip, InfiniteScrollCustomEvent,  
  IonInfiniteScroll, IonInfiniteScrollContent,IonIcon,
  IonList, IonItem, IonLabel, IonSpinner,
} from '@ionic/vue';
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import AdminMenu from "@/views/Admin/AdminMenu.vue";
import TableHead from "@/views/Table/TableHead.vue";

import { computed, defineComponent, onMounted, ref, Ref, watch } from 'vue';
import { useStore, getCancelToken, isCancelError } from '@/store';
import { useRouter } from 'vue-router';
import PageDescription from '@/components/PageDescription.vue';
import { ColumnDefinition, ListViewState, ListDefinitions, SearchDefinition } from '@/models/orderService/ReturnsListFilter';

import * as moment from 'moment-timezone';
import { filterOutline, closeOutline, eyeOutline, checkmarkDoneOutline, checkboxOutline } from "ionicons/icons";
import { useErrorBox } from '@/components/errorBox';
import { ItemAction } from '@/models/orderService/ReturnOrder';
import { useOgoEvents } from '@/composables/useOgoEvents';
import { InvoiceRowsListViewRowDto, InvoiceRowsWithActionsDto } from '@/models/configService/InvoiceModels';
import { useConfigStore } from '@/store/configStore';
import { InvoicingCategoryDto } from '@/models/umbraco/invoicingCategory';
import { CancelTokenSource } from 'axios';

export class ActiveFilter {
  filterId: string;
  filterTitle: string;
  selectObject: any;
  selectTitle: string;

  selectText(){
    return this.selectTitle;
  }

  constructor(filterId: string, filterTitle: string, selectObject: any, selectTitle: string) {
  this.filterId = filterId;
  this.filterTitle = filterTitle;
  this.selectObject = selectObject;
  this.selectTitle = selectTitle;
  }
}


export default defineComponent({
  name: 'InvoiceRowsPage',
  components: { 
    // IonSplitPane,
    PageHeader,
    PageFooter,
    IonGrid,
    IonRow,
    IonCol, 
    IonPage, 
    IonContent,
    IonButton,
    IonChip,    
    IonList,
    IonItem,
    IonIcon,
    IonLabel,
    IonSpinner,

    // MenuComponent,
    AdminMenu,
    PageDescription,
    TableHead,
    IonInfiniteScroll, IonInfiniteScrollContent,
  },
  data() {
    return {
      chartOptions: {
        responsive: true
      }
    }
  },
  setup (){
    const store = useStore();
    const router = useRouter();
    const configStore = useConfigStore();

    const { showError } = useErrorBox();
    
    const momentjs: any = moment;
    moment.locale(store.state.locale ?? "en");

    const invoiceRowListDefinitions: Ref<ListDefinitions|undefined> = ref(undefined);
    const invoicingCategories: Ref<Array<InvoicingCategoryDto>> = ref([]);

    const nextLine:  Ref<number> = ref(0);
    const listResult: Ref<Array<InvoiceRowsWithActionsDto>> = ref([]);      
    const loadMoreVisible: Ref<boolean> = ref(false);
    const processing: Ref<boolean> = ref(false);
    let cancelToken: CancelTokenSource|undefined = getCancelToken();

    const initialize = async () => {
        listResult.value = [];

        processing.value = true;
        try {
          const results = await Promise.all([
            configStore.getInvoicingCategories(),
            configStore.getInvoiceRowListDefinitions(),
          ]);

          invoicingCategories.value = results[0];
          invoiceRowListDefinitions.value = results[1];

          loadListViewState();
        } 
        catch(error) {
            showError(error, "Error");
        }
        finally {
            processing.value = false;
        }
        
    };
    onMounted( initialize );
    useOgoEvents({ returnChanged: initialize });
    
    const activeFilters = computed(() => {
      if(!invoiceRowListDefinitions.value)
        return [];

      return Object.entries(invoiceRowListDefinitions.value as object)
        .filter(i=>(i[1]).inlineSearchSelectObject)
        .map (i => {
          return new ActiveFilter(i[0], i[0], i[1].inlineSearchSelectObject, i[1].inlineSearchSelectTitle);
        });
    });

    const filterChanged = (filterKey: string, _filter:ColumnDefinition, filterValue: any, filterTitle: string) => {
      if(invoiceRowListDefinitions.value) {
        if(filterValue === undefined || filterValue === null || filterValue === "" || filterTitle === undefined || filterTitle === "") {
          invoiceRowListDefinitions.value[filterKey].inlineSearchSelectObject = undefined;
          invoiceRowListDefinitions.value[filterKey].inlineSearchSelectTitle = undefined;
        } else {
        invoiceRowListDefinitions.value[filterKey].inlineSearchSelectObject = filterValue;
        invoiceRowListDefinitions.value[filterKey].inlineSearchSelectTitle = filterTitle;
        }
      }  
    }

    const sortChanged = (filterKey: string, _filter:ColumnDefinition, sort: boolean, sortDesc: boolean) => {
      // Reset old sort order
      Object.entries(invoiceRowListDefinitions.value as object).forEach(e => {
        if(e[0] !== filterKey) {
          e[1].sortOrder = undefined;
        }
      });
      
      // Update new sort order
      if(invoiceRowListDefinitions.value) {
        invoiceRowListDefinitions.value[filterKey].sortOrder = sort ? 1 : undefined;
        invoiceRowListDefinitions.value[filterKey].sortDesc = sortDesc;
      }
    }

    // Remove filter
    const removeFilter = (_event: CustomEvent, filter: ActiveFilter) => {
      if(invoiceRowListDefinitions.value) {
        invoiceRowListDefinitions.value[filter.filterId].inlineSearchSelectObject = undefined;
        invoiceRowListDefinitions.value[filter.filterId].inlineSearchSelectTitle = undefined;
      }
    }

    const saveListViewState = () => {
      if(invoiceRowListDefinitions.value) {
        const savedState = new ListViewState();
        savedState.stateFrom(invoiceRowListDefinitions.value);
        configStore.invoiceRowsListPage = savedState;
        // console.log("saveListViewState",configStore.invoiceRowsListPage);
        // console.log("saveListViewState",JSON.stringify(configStore.invoiceRowsListPage));
      }
    }

    const loadListViewState = () => {
      if(invoiceRowListDefinitions.value) {
        // console.log(configStore.invoiceRowsListPage);
        ListViewState.stateTo(configStore.invoiceRowsListPage, invoiceRowListDefinitions.value);
      }
      // console.log("loadListViewState",configStore.invoiceRowsListPage);
    }

    const actions = computed(() :Array<ItemAction> => {
      let actionList: Array<ItemAction>|undefined = undefined;
      listResult.value
        .filter(i=>i.selected)
        .forEach(i => {
          if(actionList == undefined)
            actionList = [...i.actions];
          else
            actionList = actionList.filter(j => i.actions.findIndex(k=>k.code == j.code) !== -1);
        });

      actionList = actionList ?? [];

      // Update selectCategory action with current categories
      actionList.forEach(action => {
        if(action.code === "selectCategory") {
          action.targetList =  invoicingCategories.value.map(i => { return {id: i.ogoId, text: i.fullName}});
          }          
        });

      return actionList;
    });

    watch(invoiceRowListDefinitions, async (_newValue, _oldValue) => {
      // reset and reload
      listResult.value = [];
      nextLine.value = 0;
      await loadData(50);

      saveListViewState();
    },{deep: true});

    /// Load data to list
    const loadData = async (take: number) => {
      if(!invoiceRowListDefinitions.value)
        return;

      if(cancelToken) cancelToken.cancel("Cancel old request");
      cancelToken = getCancelToken();

      const search = new SearchDefinition();
      search.limit.skip = nextLine.value;
      search.limit.take = take;

      Object.entries(invoiceRowListDefinitions.value as object).forEach(e => {
        const key = e[0] as string;
        const f = e[1] as ColumnDefinition;

        // Update new sort order
        if(f.sortOrder)
          search.sort[key] = !!f.sortDesc;

        // Update new filter
        if(f.inlineSearchSelectObject) {
          search.inlineSearch[key] = f.inlineSearchSelectObject;
        }
      });

      // sleep for 100ms if processing previous request
      if(processing.value)
        await new Promise(r => setTimeout(r, 100));
      
      processing.value = true;
      try {
        loadMoreVisible.value = false;
        const r = await configStore.getInvoiceRowList(search, cancelToken);
        r.forEach(row => { listResult.value.push(row); });
        nextLine.value += r.length;

        loadMoreVisible.value = r.length >= take;
      } catch(error) {
        // No errorbox if cancel
        if(isCancelError(error)){
          return;
        }

        showError(error, "Error");
        loadMoreVisible.value = false;
      } finally {
        processing.value = false;
      }
    }

    const ionInfinite = async (ev: InfiniteScrollCustomEvent) => {
      await loadData(50);
      ev.target.complete();
    };

    const runAction = async (action: ItemAction) => {
      processing.value = true;
      try {
        const itemList = listResult.value.filter(i=>i.selected);
        itemList.forEach(i => { i.actions = []; });
        const updatedList = await configStore.runInvoiceRowAction(itemList, action.code, action.targetId);

        listResult.value = listResult.value.map(i => {
          const updated = updatedList.find(j=>j.id == i.id);
          return updated ?? i;
        });
      } catch(error) {
        showError(error, "Error");
      } finally {
        processing.value = false;
      }
    }
      
    const getCategoryName = (wp: InvoiceRowsListViewRowDto) => {
      if(!wp.categoryId)
        return "";

      return invoicingCategories.value.find(i => i.ogoId == wp.categoryId)?.fullName ?? "-" 
    }

  return {
    router,invoiceRowListDefinitions,filterChanged,sortChanged,activeFilters,removeFilter,
    filterOutline,closeOutline,eyeOutline,checkmarkDoneOutline, checkboxOutline,
    listResult,loadData,ionInfinite,
    momentjs,loadMoreVisible,getCategoryName,
    processing, actions, runAction,
  }
}
});

