
import { IonPage, IonContent, IonButton, } from '@ionic/vue';
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";
import AdminMenu from "@/views/Admin/AdminMenu.vue";

import { defineComponent, onMounted, ref, Ref} from 'vue';

import moment from 'moment-timezone';

import { useAuthStore } from '@/store/authStore';
import { useErrorBox } from "@/components/errorBox";
import { useRouter } from 'vue-router';
import { eyeOutline, eyeOffOutline, } from "ionicons/icons";
import { useNotificationStore } from '@/store/notificationStore';
import { getMessageApprovalTypeName, getMessageRecipientTypeName, TransactionalMessageDto } from '@/models/notificationService/Transactional';

export default defineComponent({
  name: 'TransactionalMessageList',
  components: { 
    PageHeader,
    PageFooter,
    IonPage, IonContent, IonButton,
    AdminMenu,
  },
  setup () {

    const auth = useAuthStore();
    const router = useRouter()
    const notificationStore = useNotificationStore();

    const momentjs = (a: any): moment.Moment => { return moment(a); }

    const messages:Ref<Array<TransactionalMessageDto>> = ref([]);

    const { showError } = useErrorBox();

    onMounted(async () => {
        if(!auth.filteredRoles.includes("Admins")){
            await showError("Missing admins role", "Missing permission");
            return;
        }
        messages.value = await notificationStore.getTransactionalMessages();
    });


    return {
      messages,
      momentjs,
      getMessageRecipientTypeName, getMessageApprovalTypeName,
      router, eyeOutline, eyeOffOutline
    }
  }
});

