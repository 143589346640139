import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.text !== 'null')
      ? (_openBlock(), _createBlock(_component_ion_input, {
          key: 0,
          debounce: 500,
          label: "enter text",
          labelPlacement: "floating",
          onIonInput: _cache[0] || (_cache[0] = ($event: any) => (_ctx.filterChanged($event))),
          fill: "outline",
          modelValue: _ctx.text,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.text) = $event)),
          enterkeyhint: "search"
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    (_ctx.filter.inlineSearch==='TEXT_OR_NULL')
      ? (_openBlock(), _createBlock(_component_ion_toggle, {
          key: 1,
          checked: _ctx.text=='null',
          onIonChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.toggleChanged($event)))
        }, {
          default: _withCtx(() => [
            _createTextVNode("Show empty only")
          ]),
          _: 1
        }, 8, ["checked"]))
      : _createCommentVNode("", true)
  ], 64))
}