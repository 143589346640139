import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, vShow as _vShow, withDirectives as _withDirectives } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_radio = _resolveComponent("ion-radio")!
  const _component_ion_radio_group = _resolveComponent("ion-radio-group")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_ion_item, null, {
      default: _withCtx(() => [
        (_ctx.filter.inlineSearch==='SELECT_OR_NULL')
          ? (_openBlock(), _createBlock(_component_ion_toggle, {
              key: 0,
              checked: _ctx.nullSelected,
              onIonChange: _cache[0] || (_cache[0] = ($event: any) => (_ctx.toggleChanged($event)))
            }, {
              default: _withCtx(() => [
                _createTextVNode("Show empty only")
              ]),
              _: 1
            }, 8, ["checked"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _withDirectives(_createVNode(_component_ion_radio_group, {
      allowEmptySelection: true,
      onIonChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.filterChanged($event))),
      modelValue: _ctx.selectedItem,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedItem) = $event))
    }, {
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.selectOptions, (c) => {
          return (_openBlock(), _createBlock(_component_ion_item, {
            key: c.title
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_radio, {
                value: c.value
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(c.title), 1)
                ]),
                _: 2
              }, 1032, ["value"])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 8, ["modelValue"]), [
      [_vShow, !_ctx.nullSelected]
    ])
  ], 64))
}