import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_page_header = _resolveComponent("page-header")!
  const _component_ReturnMenu = _resolveComponent("ReturnMenu")!
  const _component_PageDescription = _resolveComponent("PageDescription")!
  const _component_PostPurchaseSettings = _resolveComponent("PostPurchaseSettings")!
  const _component_ReturnSettings = _resolveComponent("ReturnSettings")!
  const _component_ProviderSettings = _resolveComponent("ProviderSettings")!
  const _component_TemplateList = _resolveComponent("TemplateList")!
  const _component_ReturnReasons = _resolveComponent("ReturnReasons")!
  const _component_ClaimReasons = _resolveComponent("ClaimReasons")!
  const _component_page_footer = _resolveComponent("page-footer")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, {
    class: "ion-page",
    id: "main"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_page_header, {
        hilight: _ctx.isDirty,
        pageName: "Post-purchase settings"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_button, {
            color: "tertiary",
            onClick: _ctx.save,
            disabled: !_ctx.isDirty
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, { icon: _ctx.cloudDoneOutline }, null, 8, ["icon"]),
              _createTextVNode(" Save")
            ]),
            _: 1
          }, 8, ["onClick", "disabled"])
        ]),
        _: 1
      }, 8, ["hilight"]),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createVNode(_component_ReturnMenu),
          _createVNode(_component_PageDescription, {
            pageName: "Post-purchase settings",
            pageDescription: "Enable and configure notifications and returns here",
            breadCrumbs: [{title: 'Return settings', active: true, url: '/returns/settings'}]
          }),
          _createVNode(_component_PostPurchaseSettings, { ref: "ppsSettings" }, null, 512),
          _createVNode(_component_ReturnSettings, { ref: "returnSettings" }, null, 512),
          _createVNode(_component_ProviderSettings, { ref: "providerSettings" }, null, 512),
          _createVNode(_component_TemplateList, { ref: "templateList" }, null, 512),
          _createVNode(_component_ReturnReasons),
          _createVNode(_component_ClaimReasons),
          _createVNode(_component_page_footer)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}