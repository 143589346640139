
import { useAuthStore } from '@/store/authStore';
import { useMerchantStore } from '@/store/merchantStore';
import { IonMenu, IonHeader, IonContent, IonToolbar, IonIcon, } from '@ionic/vue';
import { images, square, triangle, documentOutline, repeatOutline, lockClosedOutline, logInOutline, } from 'ionicons/icons';
import { computed, defineComponent } from 'vue';
import { useRouter } from 'vue-router';

  export default defineComponent({
    name: 'MenuComponent',
    components: { 
        IonMenu, 
        IonHeader,
        IonToolbar,
        IonContent,
        IonIcon,
    },
    setup(){
      const merchantStore = useMerchantStore();
      const auth = useAuthStore();
      const router = useRouter();
      const adminsRole = computed(()=>auth.filteredRoles.includes("Admins"));
      const staffRole = computed(()=>auth.filteredRoles.includes("Staff"));

      const channel = computed(()=>merchantStore.currentChannel);

      const isActive = (menuRoot: string) => {
        return router.currentRoute.value.meta?.menuRoot === menuRoot;
      }

      const jumpToMyOgo1 = () => {        
        window.location.assign(auth.myOGO1Domain);
      }

      return {
        images, square, triangle, documentOutline, repeatOutline, lockClosedOutline,logInOutline,
        channel,
        router,
        jumpToMyOgo1,
        adminsRole, staffRole,
        isActive,
      }
    }
  });
