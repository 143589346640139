
import { IonButton, IonButtons, IonCol, IonContent, IonHeader, IonIcon, IonInput, IonItem, IonModal, IonText, IonTitle, IonToolbar, toastController,} from '@ionic/vue';

import { computed, defineComponent, onMounted, ref, Ref, watch, } from 'vue';
import { useErrorBox } from '@/components/errorBox';
import { useAuthStore } from '@/store/authStore';
import { pencilOutline, cogOutline, } from 'ionicons/icons';
import { stringToHash } from '@/utils/changeTracking';
import { storeToRefs } from 'pinia';
import { useOrderStore } from '@/store/orderStore';
import { PurchaseOrderDto } from '@/models/orderService/Fabrikator';
import { FabrikatorConfig, MerchantConfigDto } from '@/models/merchantService/Config';
import { useMerchantStore } from '@/store/merchantStore';
import ModalHelpButton from '@/components/ModalHelpButton.vue';

export default defineComponent({
  name: 'FabricatorPO',
  components: {
    IonItem,
    IonInput,
    IonModal,
    IonHeader,
    IonToolbar,
    IonContent,
    IonButton,
    IonButtons,
    IonTitle,
    IonCol,
    IonText,
    IonIcon,
    ModalHelpButton,
  },
  setup (props, {expose}){
    const auth = useAuthStore();
    const orderStore = useOrderStore();
    const merchantStore = useMerchantStore();

    const { showError } = useErrorBox();

    const poList: Ref<Array<PurchaseOrderDto>> = ref([]);
    const config: Ref<FabrikatorConfig> = ref(new FabrikatorConfig());
    const configVisble: Ref<boolean> = ref(false);

    const isAdmin = computed(() => { return auth.filteredRoles.includes("Admins"); });
    const { merchantId, channelId } = storeToRefs(auth);
    

    const initialize = async ()=> {
      if(!merchantId.value || !channelId.value)
        return;
      
      savedHash.value = 0;

      try {
        const c = await merchantStore.getConfig(merchantId.value, "fabrikator");
        if(c?.fabrikator && c.fabrikator.authToken !== ""){
          config.value = c.fabrikator;

          savedHash.value = hashComponentState.value;
          poList.value = await orderStore.getFabrikatorPurchaseOrders(merchantId.value, channelId.value);
        } else {
          savedHash.value = hashComponentState.value;
        }
        


      } catch (err) {
        await showError(err, "Error"); 
      }
    }

    onMounted(initialize);
    watch(channelId, initialize);

    const save = async() => {
      if(!merchantId.value || !channelId.value)
        return;

      try {

        const merchantConfig = new MerchantConfigDto();
        merchantConfig.fabrikator = config.value;

        await merchantStore.updateConfig(merchantId.value, merchantConfig);

        // Update hash
        savedHash.value = hashComponentState.value;
        
        const toast = await toastController.create({
            message: "Return settings saved successfully!",
            duration: 1500,
            position: "top",
            color: "primary",
          });

        await toast.present();

        location.reload();
        
      } catch (e) {
          showError(e, "Error saving settings!");
      }      
    }

    const toStockUpte = async (id: number) => {
      if(!merchantId.value || !channelId.value)
        return;

      try {
        const r = await orderStore.generateStockUpdateFromFabrikatorPurchaseOrder(merchantId.value, channelId.value, id);

        const toast = await toastController.create({
            message: `Stock Update ${r.autoReference} generated successfully!`,
            duration: 1500,
            position: "top",
            color: "primary",
          });

        await toast.present();
      } catch (e) {
        showError(e, "Error generating stock update");
      }
    }

   
    //#region isDirty check for relative simple components
    const savedHash: Ref<number> = ref(0);
    const hashComponentState = computed(() => {
      return stringToHash(JSON.stringify({
        config: config,
      }));
    });

    const isDirty = computed(() => {
      if (savedHash.value === 0)
        return false;
      return savedHash.value !== hashComponentState.value;
    });
    //#endregion

    expose({ isDirty, save });

  return { 
    poList,
    pencilOutline, cogOutline,
    isDirty,
    isAdmin,
    toStockUpte,
    config, configVisble,
    save,
  };
}
});

