
  import { Link } from '@/components/PageDescription.vue';
  import { IonGrid, IonRow, IonCol, IonButton} from '@ionic/vue';
  import { defineComponent } from 'vue';
  import { useRouter } from 'vue-router';
  import { useAuthStore } from '@/store/authStore';

  export default defineComponent({
    name: 'DashboardMenu',
    components: { 
        IonGrid,
        IonRow, 
        IonCol, 
        IonButton,      
    },
    setup(props, {slots}){
      const router = useRouter();

      const auth = useAuthStore();

      let links: Array<Link> = [{
        active: false,
        title: "Shipments",
        url: "/",
      },{
        active: false,
        title: "Returns",
        url: "/dashboard/returns",
      },{
        active: false,
        title: "CO2",
        url: "/dashboard/co2",
      },{
        active: false,
        title: "Costs",
        url: "/dashboard/invoice",
      },{
        active: false,
        title: "Costs drill-down",
        url: "/dashboard/costdetails",
      }];

      links = links
      .filter(value =>{
        if(!value.role)
          return true;
        return auth.filteredRoles.includes(value.role);
      });

      const isActive = (url: string) =>{
        const currentPath = router.currentRoute.value.path;
        return currentPath == url;
      }

      const defaultSlotUsed = () => {
        const defaultSlot = slots?.default;
        return !!defaultSlot;
      }

      return {router,isActive, links, defaultSlotUsed,}
    }
  });
