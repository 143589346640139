

import { defineComponent, Ref, ref, type PropType, onMounted } from 'vue';
import { IonRadioGroup, IonRadio, IonItem, IonToggle, } from "@ionic/vue";
import { ColumnDefinition } from '@/models/orderService/ReturnsListFilter';

export class SelectOption {
  title: string;
  value: any;
  constructor(title: string, value: any) {
    this.title = title;
    this.value = value;
  }
}

  export default defineComponent({
    name: "RadioSelect",
    props: {
      title: { type: String, required: true },
      filterKey:  { type: String, required: true },
      filter: { type: Object as PropType<ColumnDefinition>, required: true },
    },
    emits: {
      filterChanged: (_event: any, _filter: any, _value: any, _text: any) => true,
    },
    components: {
      IonRadioGroup,
      IonRadio,
      IonItem,
      IonToggle,
    },
    setup (props, emits) {
      const selectOptions: Ref<Array<SelectOption>> = ref([]);
      const selectedItem: Ref<any> = ref(undefined);
      const nullSelected: Ref<boolean> = ref(false);

      onMounted ( async() => {
        const entries = Object.entries(props.filter.columnData);
        selectOptions.value = [];
        const tmpList: Array<SelectOption> = [];
        entries.forEach(e => {
          if (typeof e[1] === 'string')
          {
            tmpList.push(new SelectOption(e[1], e[0]));
          } else if(e[1].title) {
            tmpList.push(new SelectOption(e[1].title, e[0]));
          } else if(e[1].name) {
            tmpList.push(new SelectOption(e[1].name, e[0]));
          }
        });
        selectOptions.value = tmpList.sort((a,b) => a.title.localeCompare(b.title));

        const oldValue = (props.filter?.inlineSearchSelectObject as any)?.value;

        if(oldValue){
          if(oldValue == 'null'){
            nullSelected.value = true;
          } else {
            setTimeout(() => { selectedItem.value = oldValue; }, 100);
          }        
        }
      });

      const filterChanged = (event: any) => {
        if(nullSelected.value){
          emits.emit('filterChanged', props.filterKey, props.filter, {'value': 'null'}, 'empty');
        } else {
          const selected = selectOptions.value.find(s => s.value == event.detail.value);
          emits.emit('filterChanged', props.filterKey, props.filter, {'value': selected?.value}, selected?.title);
        }
      }

      const toggleChanged = (event: any) => {
        if(event.detail.checked) {
          nullSelected.value = true;
          emits.emit('filterChanged', props.filterKey, props.filter, {'value': 'null'}, 'empty');
        } else {
          nullSelected.value = false;
          if(selectedItem.value)
            emits.emit('filterChanged', props.filterKey, props.filter, {'value': selectedItem.value}, selectedItem.value);
          else
            emits.emit('filterChanged', props.filterKey, props.filter, {'value': undefined}, undefined);
        }
      }

      return { filterChanged,selectOptions,selectedItem, nullSelected, toggleChanged, }
    }
  });
