import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8ae35e78"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "tree-item__content"
}
const _hoisted_2 = {
  key: 0,
  fill: "outline"
}
const _hoisted_3 = ["innerHTML"]
const _hoisted_4 = {
  class: "tree-item__content__description",
  style: {"width":"200px","flex-grow":"0","display":"flex","flex-direction":"column","justify-content":"center","overflow":"initial"}
}
const _hoisted_5 = {
  class: "tree-item__costs",
  style: {"width":"100px","flex-grow":"0","text-align":"right","vertical-align":"middle","display":"flex","flex-direction":"column","justify-content":"center","overflow":"initial"}
}
const _hoisted_6 = {
  key: 0,
  style: {"flex-grow":"0"},
  class: "quantity"
}
const _hoisted_7 = {
  key: 1,
  class: "quantity"
}
const _hoisted_8 = {
  key: 2,
  style: {"flex-grow":"0"},
  class: "amount"
}
const _hoisted_9 = {
  key: 3,
  class: "amount"
}
const _hoisted_10 = {
  key: 4,
  style: {"flex-grow":"0"},
  class: "alt-amount"
}
const _hoisted_11 = {
  key: 5,
  class: "alt-amount"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_tree_item = _resolveComponent("tree-item", true)!

  return (_ctx.source && !_ctx.source.isEmpty() && _ctx.isVisible)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: _normalizeClass(["tree-item", 'level-'+_ctx.level])
      }, [
        (!_ctx.source.isEmpty())
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(_component_ion_button, {
                class: "tree-item__button",
                onClick: _ctx.source?.toggleExpanded,
                disabled: (_ctx.source?.children.length ?? 0) == 0
              }, {
                default: _withCtx(() => [
                  (_ctx.source?.expanded)
                    ? (_openBlock(), _createBlock(_component_ion_icon, {
                        key: 0,
                        icon: _ctx.chevronDownOutline,
                        size: "small"
                      }, null, 8, ["icon"]))
                    : _createCommentVNode("", true),
                  (!_ctx.source?.expanded)
                    ? (_openBlock(), _createBlock(_component_ion_icon, {
                        key: 1,
                        icon: _ctx.chevronForwardOutline,
                        size: "small"
                      }, null, 8, ["icon"]))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              }, 8, ["onClick", "disabled"]),
              (!_ctx.descriptionVisible)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.descriptionVisible = !!_ctx.source?.description)),
                    class: _normalizeClass(["tree-item__content__title", { 'help-click': !!_ctx.source?.description }]),
                    style: {"flex-grow":"1","flex-shrink":"0"}
                  }, [
                    _createElementVNode("p", null, [
                      _createTextVNode(_toDisplayString(_ctx.source?.title) + " ", 1),
                      (_ctx.source?.description && !_ctx.descriptionVisible)
                        ? (_openBlock(), _createElementBlock("a", _hoisted_2, "ⓘ"))
                        : _createCommentVNode("", true)
                    ])
                  ], 2))
                : _createCommentVNode("", true),
              (_ctx.descriptionVisible)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 1,
                    onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.descriptionVisible=false)),
                    class: "tree-item__content__title help-click",
                    innerHTML: _ctx.source?.description
                  }, null, 8, _hoisted_3))
                : _createCommentVNode("", true),
              _createElementVNode("div", _hoisted_4, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.source?.dimensions, (dimension) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: dimension.dimension,
                    style: {"width":"200px","display":"flex","justify-content":"space-between"}
                  }, [
                    _createElementVNode("div", null, _toDisplayString(dimension.dimension), 1),
                    _createTextVNode(),
                    _createElementVNode("div", null, _toDisplayString(_ctx.formatter.format(dimension.totalEur)), 1)
                  ]))
                }), 128))
              ]),
              _createElementVNode("div", _hoisted_5, [
                (_ctx.quantity && _ctx.source?.unit)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString(_ctx.quantity.toFixed(0)) + "  " + _toDisplayString(_ctx.source?.unit ? _ctx.source!.unit : 'x'), 1))
                  : (_openBlock(), _createElementBlock("p", _hoisted_7, " ")),
                (_ctx.quantity)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_8, _toDisplayString(_ctx.amount), 1))
                  : (_openBlock(), _createElementBlock("p", _hoisted_9, " ")),
                (_ctx.quantity && _ctx.showAltData)
                  ? (_openBlock(), _createElementBlock("p", _hoisted_10, _toDisplayString(_ctx.altAmount), 1))
                  : (_openBlock(), _createElementBlock("p", _hoisted_11, " "))
              ]),
              _createVNode(_component_ion_button, {
                onClick: _ctx.openDetails,
                color: "primary",
                fill: "clear",
                style: {"flex-grow":"0","flex-shrink":"0"}
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, { icon: _ctx.searchOutline }, null, 8, ["icon"]),
                  _createTextVNode(" rows")
                ]),
                _: 1
              }, 8, ["onClick"])
            ]))
          : _createCommentVNode("", true),
        (_ctx.source?.expanded)
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.source?.children, (subItem) => {
              return (_openBlock(), _createElementBlock("div", {
                key: subItem.id,
                class: "tree-item__children"
              }, [
                _createVNode(_component_tree_item, {
                  source: subItem,
                  level: _ctx.level+1,
                  "show-alt-data": _ctx.showAltData,
                  onOpenDetails: _ctx.forwardOpenDetails
                }, null, 8, ["source", "level", "show-alt-data", "onOpenDetails"])
              ]))
            }), 128))
          : _createCommentVNode("", true)
      ], 2))
    : _createCommentVNode("", true)
}