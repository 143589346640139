import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-50acb3b5"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-layout" }
const _hoisted_2 = {
  key: 2,
  class: "list-table",
  style: {"margin-top":"0px"}
}
const _hoisted_3 = { class: "table-header" }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_PageDescription = _resolveComponent("PageDescription")!
  const _component_AdminMenu = _resolveComponent("AdminMenu")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_TableHead = _resolveComponent("TableHead")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_spinner = _resolveComponent("ion-spinner")!
  const _component_ion_infinite_scroll_content = _resolveComponent("ion-infinite-scroll-content")!
  const _component_ion_infinite_scroll = _resolveComponent("ion-infinite-scroll")!
  const _component_page_footer = _resolveComponent("page-footer")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, {
    class: "ion-page",
    id: "main"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_page_header, {
        pageName: "Invoice rows",
        actions: _ctx.actions,
        onClickAction: _ctx.runAction
      }, null, 8, ["actions", "onClickAction"]),
      _createVNode(_component_ion_content, { fullscreen: true }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            false
              ? (_openBlock(), _createBlock(_component_PageDescription, {
                  key: 0,
                  pageName: "Invoice rows",
                  pageDescription: "List and search invoice rows.",
                  breadCrumbs: [{title: 'Dashboard', active: true, url: '/home'}]
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_AdminMenu),
            _createVNode(_component_ion_grid, { style: {"margin-top":"0px","width":"100%","flex-grow":"0"} }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_col, null, {
                      default: _withCtx(() => [
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.activeFilters, (activeFilter) => {
                          return (_openBlock(), _createBlock(_component_ion_chip, {
                            outline: true,
                            color: "primary",
                            key: activeFilter.filterId,
                            onClick: ($event: any) => (_ctx.removeFilter($event, activeFilter))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, {
                                icon: _ctx.filterOutline,
                                color: "primary"
                              }, null, 8, ["icon"]),
                              _createVNode(_component_ion_label, null, {
                                default: _withCtx(() => [
                                  _createTextVNode(_toDisplayString(activeFilter.filterTitle) + ":" + _toDisplayString(activeFilter.selectText()), 1)
                                ]),
                                _: 2
                              }, 1024),
                              _createVNode(_component_ion_icon, { icon: _ctx.closeOutline }, null, 8, ["icon"])
                            ]),
                            _: 2
                          }, 1032, ["onClick"]))
                        }), 128))
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }),
            false
              ? (_openBlock(), _createBlock(_component_ion_list, {
                  key: 1,
                  style: {"margin-top":"0px"}
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries((_ctx.invoiceRowListDefinitions as object)), (filter, index) => {
                      return (_openBlock(), _createBlock(_component_ion_item, { key: index }, {
                        default: _withCtx(() => [
                          _createVNode(_component_TableHead, {
                            title: filter[0],
                            filter: filter[1],
                            filterKey: filter[0],
                            onFilterChanged: _ctx.filterChanged,
                            onSortChanged: _ctx.sortChanged
                          }, null, 8, ["title", "filter", "filterKey", "onFilterChanged", "onSortChanged"])
                        ]),
                        _: 2
                      }, 1024))
                    }), 128))
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            (_ctx.invoiceRowListDefinitions)
              ? (_openBlock(), _createElementBlock("table", _hoisted_2, [
                  _createElementVNode("tr", _hoisted_3, [
                    _createElementVNode("th", null, [
                      (!_ctx.listResult.filter(i=>i.selected).length)
                        ? (_openBlock(), _createBlock(_component_ion_button, {
                            key: 0,
                            class: "mini-button",
                            style: {"margin-right":"0","margin-left":"0"},
                            size: "small",
                            fill: "clear",
                            color: "light",
                            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.listResult.forEach(i=>i.selected=true)))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, { icon: _ctx.checkmarkDoneOutline }, null, 8, ["icon"])
                            ]),
                            _: 1
                          }))
                        : (_openBlock(), _createBlock(_component_ion_button, {
                            key: 1,
                            class: "mini-button",
                            style: {"margin-right":"0","margin-left":"0"},
                            size: "small",
                            fill: "clear",
                            color: "tertiary",
                            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.listResult.forEach(i=>i.selected=false)))
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_ion_icon, { icon: _ctx.closeOutline }, null, 8, ["icon"]),
                              _createTextVNode(_toDisplayString(_ctx.listResult.filter(i=>i.selected).length), 1)
                            ]),
                            _: 1
                          }))
                    ]),
                    _createElementVNode("th", null, [
                      _createVNode(_component_TableHead, {
                        title: "Invoice #",
                        filter: _ctx.invoiceRowListDefinitions.invoiceNumber,
                        "filter-key": 'invoiceNumber',
                        onFilterChanged: _ctx.filterChanged,
                        onSortChanged: _ctx.sortChanged
                      }, null, 8, ["filter", "onFilterChanged", "onSortChanged"])
                    ]),
                    _createElementVNode("th", null, [
                      _createVNode(_component_TableHead, {
                        title: "D begin",
                        filter: _ctx.invoiceRowListDefinitions.deliveryBegin,
                        "filter-key": 'deliveryBegin',
                        onFilterChanged: _ctx.filterChanged,
                        onSortChanged: _ctx.sortChanged
                      }, null, 8, ["filter", "onFilterChanged", "onSortChanged"])
                    ]),
                    _createElementVNode("th", null, [
                      _createVNode(_component_TableHead, {
                        title: "D end",
                        filter: _ctx.invoiceRowListDefinitions.deliveryEnd,
                        "filter-key": 'editTime',
                        onFilterChanged: _ctx.filterChanged,
                        onSortChanged: _ctx.sortChanged
                      }, null, 8, ["filter", "onFilterChanged", "onSortChanged"])
                    ]),
                    _createElementVNode("th", null, [
                      _createVNode(_component_TableHead, {
                        title: "Description",
                        filter: _ctx.invoiceRowListDefinitions.description,
                        "filter-key": 'description',
                        onFilterChanged: _ctx.filterChanged,
                        onSortChanged: _ctx.sortChanged
                      }, null, 8, ["filter", "onFilterChanged", "onSortChanged"])
                    ]),
                    _createElementVNode("th", null, [
                      _createVNode(_component_TableHead, {
                        title: "Quantity",
                        filter: _ctx.invoiceRowListDefinitions.quantity,
                        "filter-key": 'quantity',
                        onFilterChanged: _ctx.filterChanged,
                        onSortChanged: _ctx.sortChanged
                      }, null, 8, ["filter", "onFilterChanged", "onSortChanged"])
                    ]),
                    _createElementVNode("th", null, [
                      _createVNode(_component_TableHead, {
                        title: "Total",
                        filter: _ctx.invoiceRowListDefinitions.total,
                        "filter-key": 'total',
                        onFilterChanged: _ctx.filterChanged,
                        onSortChanged: _ctx.sortChanged
                      }, null, 8, ["filter", "onFilterChanged", "onSortChanged"])
                    ]),
                    _createElementVNode("th", null, [
                      _createVNode(_component_TableHead, {
                        title: "Total €",
                        filter: _ctx.invoiceRowListDefinitions.totalEur,
                        "filter-key": 'totalEur',
                        onFilterChanged: _ctx.filterChanged,
                        onSortChanged: _ctx.sortChanged
                      }, null, 8, ["filter", "onFilterChanged", "onSortChanged"])
                    ]),
                    _createElementVNode("th", null, [
                      _createVNode(_component_TableHead, {
                        title: "Purchase €",
                        filter: _ctx.invoiceRowListDefinitions.purchaseEur,
                        "filter-key": 'purchaseEur',
                        onFilterChanged: _ctx.filterChanged,
                        onSortChanged: _ctx.sortChanged
                      }, null, 8, ["filter", "onFilterChanged", "onSortChanged"])
                    ]),
                    _createElementVNode("th", null, [
                      _createVNode(_component_TableHead, {
                        title: "Purchase number",
                        filter: _ctx.invoiceRowListDefinitions.purchaseNumber,
                        "filter-key": 'purchaseNumber',
                        onFilterChanged: _ctx.filterChanged,
                        onSortChanged: _ctx.sortChanged
                      }, null, 8, ["filter", "onFilterChanged", "onSortChanged"])
                    ]),
                    _createElementVNode("th", null, [
                      _createVNode(_component_TableHead, {
                        title: "Invoice state",
                        filter: _ctx.invoiceRowListDefinitions.invoiceState,
                        "filter-key": 'invoiceState',
                        onFilterChanged: _ctx.filterChanged,
                        onSortChanged: _ctx.sortChanged
                      }, null, 8, ["filter", "onFilterChanged", "onSortChanged"])
                    ]),
                    _createElementVNode("th", null, [
                      _createVNode(_component_TableHead, {
                        title: "Customer id",
                        filter: _ctx.invoiceRowListDefinitions.invoiceCustomerId,
                        "filter-key": 'invoiceCustomerId',
                        onFilterChanged: _ctx.filterChanged,
                        onSortChanged: _ctx.sortChanged
                      }, null, 8, ["filter", "onFilterChanged", "onSortChanged"])
                    ]),
                    _createElementVNode("th", null, [
                      _createVNode(_component_TableHead, {
                        title: "Dimension",
                        filter: _ctx.invoiceRowListDefinitions.dimension,
                        "filter-key": 'dimension',
                        onFilterChanged: _ctx.filterChanged,
                        onSortChanged: _ctx.sortChanged
                      }, null, 8, ["filter", "onFilterChanged", "onSortChanged"])
                    ]),
                    _createElementVNode("th", null, [
                      _createVNode(_component_TableHead, {
                        title: "Category",
                        filter: _ctx.invoiceRowListDefinitions.categoryId,
                        "filter-key": 'categoryId',
                        onFilterChanged: _ctx.filterChanged,
                        onSortChanged: _ctx.sortChanged
                      }, null, 8, ["filter", "onFilterChanged", "onSortChanged"])
                    ])
                  ]),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.listResult, (row, index) => {
                    return (_openBlock(), _createElementBlock("tr", {
                      key: index,
                      class: _normalizeClass({selected:row.selected}),
                      onClick: ($event: any) => (row.selected = !row.selected)
                    }, [
                      _createElementVNode("td", null, [
                        (!row.selected)
                          ? (_openBlock(), _createBlock(_component_ion_button, {
                              key: 0,
                              class: "mini-button",
                              size: "small",
                              color: "secondary",
                              fill: "solid",
                              onClick: ($event: any) => (_ctx.router.push({name: 'ReturnRequestDetails', params: {id: row.id}}))
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, { icon: _ctx.eyeOutline }, null, 8, ["icon"])
                              ]),
                              _: 2
                            }, 1032, ["onClick"]))
                          : (_openBlock(), _createBlock(_component_ion_button, {
                              key: 1,
                              class: "mini-button",
                              size: "small",
                              color: "primary",
                              fill: "clear"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_ion_icon, { icon: _ctx.checkboxOutline }, null, 8, ["icon"])
                              ]),
                              _: 1
                            }))
                      ]),
                      _createElementVNode("td", null, _toDisplayString(row.invoice?.number ?? "?"), 1),
                      _createElementVNode("td", null, _toDisplayString(row.deliveryBegin ? _ctx.momentjs(row.deliveryBegin).calendar() : ''), 1),
                      _createElementVNode("td", null, _toDisplayString(row.deliveryEnd ? _ctx.momentjs(row.deliveryEnd).calendar() : ''), 1),
                      _createElementVNode("td", null, _toDisplayString(row.description), 1),
                      _createElementVNode("td", null, _toDisplayString(row.quantity), 1),
                      _createElementVNode("td", null, _toDisplayString(row.total), 1),
                      _createElementVNode("td", null, _toDisplayString(row.totalEur), 1),
                      _createElementVNode("td", null, _toDisplayString(row.purchaseEur), 1),
                      _createElementVNode("td", null, _toDisplayString(row.purchaseNumber), 1),
                      _createElementVNode("td", null, _toDisplayString(row.invoice?.state), 1),
                      _createElementVNode("td", null, _toDisplayString(row.invoice?.customerId), 1),
                      _createElementVNode("td", null, _toDisplayString(row.dimension), 1),
                      _createElementVNode("td", null, _toDisplayString(_ctx.getCategoryName(row)), 1)
                    ], 10, _hoisted_4))
                  }), 128))
                ]))
              : _createCommentVNode("", true),
            (_ctx.processing)
              ? (_openBlock(), _createBlock(_component_ion_spinner, { key: 3 }))
              : _createCommentVNode("", true),
            (_ctx.loadMoreVisible)
              ? (_openBlock(), _createBlock(_component_ion_button, {
                  key: 4,
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.loadData(50)))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Load more")
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_ion_infinite_scroll, { onIonInfinite: _ctx.ionInfinite }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_infinite_scroll_content)
              ]),
              _: 1
            }, 8, ["onIonInfinite"]),
            _createVNode(_component_page_footer)
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}