
import { IonPage,IonTitle, IonContent, IonGrid,IonRow,IonCol,IonSelect, IonSelectOption, IonText, IonButton, IonIcon } from '@ionic/vue';
import { gridOutline, downloadOutline, } from "ionicons/icons";
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";

import { Bar, Pie } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement, TooltipItem } from 'chart.js'
import { defineComponent, onMounted, ref, Ref, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { ActionTypes } from '@/store';
import { ShipmentsDashboardDataDto, ShipmentsDashboardFiltersDto, TimeFilter } from '@/models/orderService/Dashboard';
import { useRouter } from 'vue-router';
import { useAuthStore } from '@/store/authStore';
import DashboardMenu from '@/views/Dashboard/DashboardMenu.vue';
import { useOrderStore } from '@/store/orderStore';
import { useErrorBox } from '@/components/errorBox';
import ModalHelpButton from '@/components/ModalHelpButton.vue';

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale, ArcElement);

export default defineComponent({
  name: 'ShippingDashboardPage',
  components: {
    Bar, Pie,
    PageHeader,
    PageFooter,
    IonGrid,
    IonRow,
    IonCol,
    IonPage,
    IonContent,
    IonTitle,
    IonSelect,
    IonSelectOption,
    IonText,
    DashboardMenu,
    IonButton,
    IonIcon,
    ModalHelpButton,
},
  data() {
    return {
      chartOptions: {
        responsive: true
      }
    }
  },
  setup (){
    const store = useStore();
    const auth = useAuthStore();
    const router = useRouter();
    const orderStore = useOrderStore();

    const filters: Ref<ShipmentsDashboardFiltersDto|undefined> = ref(undefined);

    const timeFrameSelected: Ref<TimeFilter|undefined> = ref(undefined);
    const warehouseSelected: Ref<string|undefined> = ref(undefined);
    const shippingMethodselected: Ref<string|undefined> = ref(undefined);

    const shipData: Ref<any> = ref(undefined);
    const deliveryData: Ref<any> = ref(undefined);
    const shippingData: Ref<any> = ref(undefined);

    const checkReportLink: Ref<string|undefined> = ref(undefined); 
    const loadingReport: Ref<boolean> = ref(false); 

    const { showError } = useErrorBox();

    onMounted(async () => {

      const ok = await auth.checkAuth();
      if(!ok) {
        router.push("/login");
        return;
      }

      try {
        filters.value = await store.dispatch(ActionTypes.OS_SHIPMENT_FILTERS,);
        if(!filters.value)
        return;

        // Select first timeFrame as default
        timeFrameSelected.value = filters.value?.timeFrame[0];
        filters.value.warehouses.unshift({warehouseId: "", title: "All warehouses"});
        filters.value.shippingMethods.unshift({code: "", title: "All shipping methods"});

      } catch(e){
        console.log(e);
      }

      // Select first timeFrame as default
      timeFrameSelected.value = filters.value?.timeFrame[0];
    });

    const getPieChartColors = () => {
    return [
      "#1976D2",
      "#F78343",
      "#38DCBF",
      "#8D79C2",
      "#E89800",
      "#CF4030",
    ]
  };

  const refreshCharts = async () => {

    const dashboard  = (await store.dispatch(ActionTypes.OS_SHIPMENT_DASHBOARD,{
        timeFrame: timeFrameSelected.value?.code,
        warehouseId: warehouseSelected.value,
        ShippingMethod: shippingMethodselected.value,
        destinationCountry: undefined })) as ShipmentsDashboardDataDto;
  
    if(!dashboard) {
      shipData.value = [];
      deliveryData.value = [];
      shippingData.value = [];
      return;
    }

    if(!dashboard.shipments ) dashboard.shipments = [];
    if(!dashboard.deliveryTimes) dashboard.deliveryTimes = [];
    if(!dashboard.shippingMethods) dashboard.shippingMethods = [];     
    
    shipData.value = {
          labels: dashboard.shipments.map(m => m.title),
          datasets: [
            {
              label: "Shipments",
              data: dashboard.shipments.map(m => m.amount),
              backgroundColor: "#1976D2",
              tooltip: {
               callbacks: {
                 label: (ctx:TooltipItem<'bar'>) => `${ctx.formattedValue} shipments`                 
                }
              }
            },
          ],
        };

    deliveryData.value = {
        labels: dashboard.deliveryTimes.map(m => `${m.title}`),
        datasets: [
          {
            data: dashboard.deliveryTimes.map(m => m.percentage),
            backgroundColor: getPieChartColors(),
            borderWidth: 1,
            tooltip: {
               callbacks: {
                 label: (ctx:TooltipItem<'pie'>) => `${ctx.formattedValue}%`                 
               }
            }
          },
        ],
      };


    shippingData.value = {
        labels: dashboard.shippingMethods.map(m => m.title),
        datasets: [
          {
            data: dashboard.shippingMethods.map(m => m.percentage),
            backgroundColor: getPieChartColors(),
            borderWidth: 1,
            tooltip: {
              mode: 'label',
              // https://www.chartjs.org/docs/latest/configuration/tooltip.html#tooltip-callbacks
              callbacks: {
                 label: (ctx:TooltipItem<'pie'>) => `${ctx.formattedValue}%`                 
               }
            },
          },
        ],
      };
  };

  watchEffect(async ()=>{
    if(!timeFrameSelected.value && 
      !warehouseSelected.value && 
      !shippingMethodselected.value)
      return;
      
    await refreshCharts();
  })


  const getReport = async () => {

      loadingReport.value = true;
      try {        
        const res = await orderStore.getShipmentDashboardReport(timeFrameSelected.value?.code, warehouseSelected.value, shippingMethodselected.value, undefined);

        const FILE = window.URL.createObjectURL(new Blob([res]));                     
        checkReportLink.value = FILE;
      } catch (err) {
        await showError(err, "Error");
      } finally {
        loadingReport.value = false;
      }
    }
  
  return {
    timeFrameSelected,warehouseSelected,shippingMethodselected,
    filters,shipData,deliveryData,shippingData,router, checkReportLink, getReport, loadingReport,
    gridOutline, downloadOutline,
  }
}
});

