import { defineStore } from 'pinia'
import { StorageSerializers, useLocalStorage } from '@vueuse/core'
import { useAuthStore } from './authStore';
import { prettyResponse, getNow, postNow, patchNow, deleteNow, ogoServices, putNow } from './index';
import { AxiosError, AxiosResponse } from 'axios';
import { ChannelNotificationsSettingsDto, ChannelNotificationsSettingsUpdateDto, SendTestDto, ProvidersListResponse, UiDataField } from '@/models/notificationService/Providers';
import { NotificationFrameDto, NotificationTemplateDto } from '@/models/notificationService/NotificationFrame';
import { MessageDto } from '@/models/notificationService/Messaging';
import { eventBus } from '@/composables/useOgoEvents';
import { WebHookRedisListModel, WebHookStatus } from '@/models/notificationService/WebHooks';
import { RecipientTransactionsDto, TransactionalMessageDto } from '@/models/notificationService/Transactional';

export const useNotificationStore = defineStore('notification', {
  state: () => ({
    test: useLocalStorage("test", "",{ serializer: StorageSerializers.object,  mergeDefaults: true  }),
  }),

  getters: {
  }, 

  actions: {

    //#region Notification Settings
    async getChannelNotificationSettings(channelId: number) {
      try {
        const r = await getNow(`settings/channel/${channelId}`,ogoServices.ns, undefined) as AxiosResponse<ChannelNotificationsSettingsDto>;
        return r.data;
      } catch (reason) {

        // create new settigns if not found
        const errorResponse = reason as AxiosError;
        if(errorResponse.response?.status == 404) {
          const newSettings = new ChannelNotificationsSettingsDto();
          newSettings.channelId = channelId;
          return await this.createChannelNotificationSettings(channelId,newSettings);
        }

        const authStore = useAuthStore();
        authStore.logoutNeeded(reason);
        throw (prettyResponse(reason,undefined));
      }      
    },

    async createChannelNotificationSettings(channelId: number, settings: ChannelNotificationsSettingsDto) {
      try {
          const r = await postNow(`settings/channel/${channelId}`,ogoServices.ns, settings) as AxiosResponse<ChannelNotificationsSettingsDto>;
          return r.data;
      } catch (reason) {
          const authStore = useAuthStore();
          authStore.logoutNeeded(reason);
          throw (prettyResponse(reason,undefined));
      }      
    },

    async updateChannelNotificationSettings(channelId: number, settings: ChannelNotificationsSettingsUpdateDto) {
      try {
          const r = await patchNow(`settings/channel/${channelId}`,ogoServices.ns, settings) as AxiosResponse<ChannelNotificationsSettingsDto>;
          return r.data;
      } catch (reason) {
          const authStore = useAuthStore();
          authStore.logoutNeeded(reason);
          throw (prettyResponse(reason,undefined));
      }      
    },

    async getAllProviders() {
      try {
          const r = await getNow(`settings/providers`,ogoServices.ns, undefined) as AxiosResponse<ProvidersListResponse>;
          return r.data;
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }      
    },

    async getProviderParameters(provider: string) {
      try {
          const r = await getNow(`settings/providers/${provider}/parameters/list`,ogoServices.ns, undefined) as AxiosResponse<Array<UiDataField>>;
          return r.data;
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }      
    },
    //#endregion

    //#region Notification Templates
    async getFrame(channelId: number, id: string) {
      try {
          const r = await getNow(`notificationTemplates/channel/${channelId}/frame/${id}`,ogoServices.ns, undefined) as AxiosResponse<NotificationFrameDto>;
          return r.data;
      } catch (reason) {
          const authStore = useAuthStore();
          authStore.logoutNeeded(reason);
          throw (prettyResponse(reason,undefined));
      }      
    },

    async removeFrame(channelId: number, id: string) {
      try {
          await deleteNow(`notificationTemplates/channel/${channelId}/frame/${id}`,ogoServices.ns, undefined);
          return;
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }      
    },

    async getTemplate(channelId: number, usageKey: string, notificationChannel:string ) {
      try {
          const r = await getNow(`notificationTemplates/channel/${channelId}/template/${usageKey}/${notificationChannel}`,ogoServices.ns, undefined) as AxiosResponse<NotificationTemplateDto>;
          return r.data;
      } catch (reason) {
          const authStore = useAuthStore();
          authStore.logoutNeeded(reason);
          throw (prettyResponse(reason,undefined));
      }      
    },

    async getTemplates(channelId: number ) {
      try {
          const r = await getNow(`notificationTemplates/channel/${channelId}/templates`,ogoServices.ns, undefined) as AxiosResponse<Array<NotificationTemplateDto>>;
          return r.data;
      } catch (reason) {
          const authStore = useAuthStore();
          authStore.logoutNeeded(reason);
          throw (prettyResponse(reason,undefined));
      }      
    },

    async getFrames(channelId: number) {
      try {
          const r = await getNow(`notificationTemplates/channel/${channelId}/frames`,ogoServices.ns, undefined) as AxiosResponse<Array<NotificationFrameDto>>;
          return r.data;
      } catch (reason) {
          const authStore = useAuthStore();
          authStore.logoutNeeded(reason);
          throw (prettyResponse(reason,undefined));
      }      
    },

    async previewFrame(data: NotificationFrameDto) {
      try {
          const r = await postNow(`notificationTemplates/preview-frame/orders.new`, ogoServices.ns, data) as AxiosResponse<string>;
          return r.data;
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }      
    },

    async previewTemplate(data: NotificationTemplateDto, locale: string) {
      try {
          const r = await postNow(`notificationTemplates/preview-template/${locale}`, ogoServices.ns, data) as AxiosResponse<string>;
          return r.data;
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }      
    },

    async testPreviewTemplate(channelId: number, data: NotificationTemplateDto, locale: string, recipientAddress: string) {
      try {
          await postNow(`notificationTemplates/test-template/${channelId}/${locale}/${recipientAddress}`, ogoServices.ns, data);         
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }      
    },

    async addFrame(channelId: number, data: NotificationFrameDto) {
      try {
          const r = await postNow(`notificationTemplates/channel/${channelId}/frame`, ogoServices.ns, data) as AxiosResponse<NotificationFrameDto>;
          return r.data;
      } catch (reason) {
          const authStore = useAuthStore();
          authStore.logoutNeeded(reason);
          throw (prettyResponse(reason,undefined));
      }      
    },

    async addTemplate(channelId: number, data: NotificationTemplateDto) {
      try {
          const r = await postNow(`notificationTemplates/channel/${channelId}/template`, ogoServices.ns, data) as AxiosResponse<NotificationTemplateDto>;
          eventBus.emit('templateChanged', r.data);
          return r.data;
      } catch (reason) {
          const authStore = useAuthStore();
          authStore.logoutNeeded(reason);
          throw (prettyResponse(reason,undefined));
      }      
    },

    async updateTemplate(channelId: number, data: NotificationTemplateDto) {
      try {
          const r = await patchNow(`notificationTemplates/channel/${channelId}/template/${data.usageKey}/${data.notificationChannel}`, ogoServices.ns, data) as AxiosResponse<NotificationTemplateDto>;
          eventBus.emit('templateChanged', r.data);
          return r.data;
      } catch (reason) {
          const authStore = useAuthStore();
          authStore.logoutNeeded(reason);
          throw (prettyResponse(reason,undefined));
      }      
    },

    async updateFrame(channelId: number, data: NotificationFrameDto) {
      try {
          const r = await patchNow(`notificationTemplates/channel/${channelId}/frame/${data.id}`, ogoServices.ns, data) as AxiosResponse<NotificationFrameDto>;
          return r.data;
      } catch (reason) {
          const authStore = useAuthStore();
          authStore.logoutNeeded(reason);
          throw (prettyResponse(reason,undefined));
      }      
    },

    async getSampleNotificationModel(usageKey: string) {
      try {
          const r = await getNow(`notificationTemplates/sample-model/${usageKey}`,ogoServices.ns, undefined) as AxiosResponse<string>;
          return r.data;
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }      
    },
    //#endregion

    //#region Notification Sending
    async sendTestMessage(data: SendTestDto) {
      try {
          await postNow(`notifications/channel/${data.channelId}/test-message`, ogoServices.ns, data);
      } catch (reason) {
          throw (prettyResponse(reason,undefined));
      }      
    },
    //#endregion


    //#region Notification Sending
    async getMessage(channelId: number, messageId: string) {
      try {
        const r = await getNow(`messaging/channel/${channelId}/message/${messageId}`, ogoServices.ns, undefined) as AxiosResponse<MessageDto>;
        return r.data;
      } catch (reason) {
        throw (prettyResponse(reason,undefined));
      }      
    },

    /**
     * Get messages from a channel
     * @param channelId 
     * @param contextId 
     */
    async getMessages(channelId: number, contextId?: string) {
      const params = new URLSearchParams();
      if(contextId){ params.append('contextId',contextId); }
      try {
        const r = await getNow(`messaging/channel/${channelId}/search`, ogoServices.ns, params) as AxiosResponse<Array<MessageDto>>;
        return r.data;
      } catch (reason) {
        throw (prettyResponse(reason,undefined));
      }      
    },
    //#endregion
    
    //#region WebHooks
    /**
     * List all webhook messages
     * @param webhookId 
     * @returns 
     */
    async getWebHookMessages(webhookId: string) {
          const r = await getNow(`webhook/${webhookId}`,ogoServices.ns, undefined) as AxiosResponse<Array<WebHookRedisListModel>>;
          return r.data;
    },

    /**
     * Get a webhook stats
     * @param webhookId 
     * @returns 
     */
    async getWebHookStats(webhookId: string) {
      const r = await getNow(`webhook/${webhookId}/stats`,ogoServices.ns, undefined) as AxiosResponse<WebHookStatus>;
      return r.data;
    },

    /**
     * Remove ordest message
     * @param webhookId 
     * @returns 
     */
    async removeWebHookMessage(webhookId: string) {
      const r = await deleteNow(`webhook/${webhookId}`,ogoServices.ns, undefined) as AxiosResponse<Array<WebHookRedisListModel>>;
      return r.data;
    },

    /**
     * Resend webhooks
     * @param webhookId 
     * @returns 
     */
    async resendWebHook(webhookId: string) {
      await getNow(`webhook/${webhookId}/resend`,ogoServices.ns, undefined);
    },
        
    //#endregion

    //#region Transactional messages
    /**
     * List all messages
     * @param expired
     * @param started
     * @param deleted 
     * @returns 
     */
    async getTransactionalMessages(expired?: boolean, started?: boolean, deleted?: boolean) {
      const params = new URLSearchParams();
      if(expired){ params.append('expired',expired.toString()); }
      if(started){ params.append('started',started.toString()); }
      if(deleted){ params.append('deleted',deleted.toString()); }
      const r = await getNow(`transactional`,ogoServices.ns, params) as AxiosResponse<Array<TransactionalMessageDto>>;
      return r.data;
    },

    /**
     * Get single message
     * @param id 
     * @returns 
     */
    async getTransactionalMessage(id: string) {
      const r = await getNow(`transactional/${id}`,ogoServices.ns, undefined) as AxiosResponse<TransactionalMessageDto>;
      return r.data;
    },

    /**
     * Create new message
     * @param message 
     * @returns 
     */
    async createTransactionalMessage(message: TransactionalMessageDto) {
      const r = await postNow(`transactional`,ogoServices.ns, message) as AxiosResponse<TransactionalMessageDto>;
      return r.data;
    },


    /**
     * Update message
     * @param message 
     * @returns 
     */
    async updateTransactionalMessage(message: TransactionalMessageDto) {
      const r = await putNow(`transactional/${message.uid}`,ogoServices.ns, message) as AxiosResponse<TransactionalMessageDto>;
      return r.data;
    },

    /**
     * Get recipients of message
     * @param id 
     * @returns 
     */
    async getTransactionalMessageRecipients(id: string) {
      const r = await getNow(`transactional/${id}/recipients`,ogoServices.ns, undefined) as AxiosResponse<Array<RecipientTransactionsDto>>;
      return r.data;
    },

    /**
     * Add recipient to message
     * @param id 
     * @param recipient
     * @returns 
     */
        async addTransactionalMessageRecipient(id: string, recipient: string) {

          const t = new RecipientTransactionsDto();
          t.recipient = recipient;
          t.messageUid = id;
          t.uid = uuidv4();
          const r = await putNow(`transactional/${id}/recipients`,ogoServices.ns, t) as AxiosResponse<RecipientTransactionsDto>;
          return r.data;
        },
        
    
    //#endregion

  }
})


function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'
  .replace(/[xy]/g, function (c) {
      const r = Math.random() * 16 | 0, 
          v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
  });
}