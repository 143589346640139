import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createBlock(_component_ion_grid, { class: "panel-grid" }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_title, null, {
                default: _withCtx(() => [
                  _createTextVNode("Enable or disable claim reasons")
                ]),
                _: 1
              }),
              _createVNode(_component_ion_list, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.claimReasons, (reason) => {
                    return (_openBlock(), _createBlock(_component_ion_item, {
                      key: reason.id
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_toggle, {
                          modelValue: reason.enabled,
                          "onUpdate:modelValue": ($event: any) => ((reason.enabled) = $event),
                          onIonChange: ($event: any) => (_ctx.wasChanged($event,reason)),
                          labelPlacement: "start"
                        }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(reason.isRemoved ? reason.text + ' (Removed)' : reason.text), 1)
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue", "onIonChange"])
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}